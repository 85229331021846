import Vue from 'vue';
import Vuex from 'vuex';
import core, { action as coreAction, actionLoader, helper } from '@agi.packages/core';
import payment, { mutation as paymentMutation } from '@agi.packages/payment';
import sportStore, { betslip, betStatus, betType, endpoints as sportEndpoints, myBetsType, sport } from '@agi.packages/sport';
import platformStore, {
    action as platformAction,
    auth,
    endpoints as platformEndpoints,
    getter as platformGetter,
    messaging,
    mutation as platformMutation,
} from '@agi.packages/platform';

import { getComponentsWithCaptcha } from '@/js/utils/captcha';
import { MENUS } from '@/js/sheetdump';
import { casinoGameMode, casinoProviderType, sortType, toolbarIconType } from '@/js/casino-const';

import SnapshotToStorage from './plugins/SnapshotToStorage';
import translations, { getter as translationsGetter, getter as transGetter, mutation as transMutation } from './modules/translations';
import { strapi } from '@/modules/platform/endpoints';
import {
    buildCasinoCategoriesStrapiQuery,
    buildCasinoCategoryStrapiQuery,
    buildCasinoGameBrandsStrapiQuery,
    buildCasinoGameBrandStrapiQuery,
    buildCasinoGamesStrapiQuery,
    buildCasinoGameStrapiQuery,
    sortCasinoGamesForFavourites,
    getKeyForGamesInCategory,
    buildCasinoGamesFavouritesFilter,
    buildCasinoGamesFilters,
    getModelsWithLocalisedName,
    getModelWithLocalisedName,
    getGameWithLocalizedRibbonName,
    getGamesWithLocalizedRibbonName,
    getSortedCasinoCategories,
} from '@/store/utils';
import { getObjectField } from '@/modules/core/utils/helper';
import { isNumber } from '@/modules/core/utils/number/isNumber';
import { flatDataAttributes } from '@/modules/core/utils/strapi/flatDataAttributes';

Vue.use(Vuex);

const IS_DEV = env.VUE_APP_ENV !== 'production';

export const mutation = {
    ADD_NOTIFICATION: 'addNotification',
    CLEAR_NOTIFICATIONS: 'removeNotifications',
    CLOSE_TOP_MENU: 'closeTopMenu',
    SET_CASINO_FAVORITES: 'setCasinoFavorites',
    SET_CASINO_LAYOUT: 'setCasinoLayout',
    SET_COUNTRIES: 'setCountries',
    SET_CURRENT_CATEGORY: 'setCurrentCategory',
    SET_GAMES_COLLECTION: 'setGamesCollection', // remove for old casino
    SET_GAMES_SORT_BY: 'setGamesSortBy', // remove for old casino
    SET_GAME_CASINO_LOAD_ERROR: 'setGameCasinoLoadError', // remove for old casino
    SET_GAMES: 'setGames',
    SET_GAME: 'setGame',
    SET_GAME_CATEGORY: 'setGameCategory',
    SET_GAME_CATEGORIES: 'setGameCategories',
    SET_GAME_BRANDS: 'setGameBrands',
    SET_GAME_BRAND: 'setGameBrand',
    SET_GAME_LAUNCH_ERROR: 'setGameLaunchError',
    SET_TOURNAMENTS: 'setTournaments',
    SET_TURNSTILE: 'ui/setTurnstile',
    TOGGLE_LEFT_MENU_COUNTRY: 'toggleLeftMenuCountry',
    TOGGLE_LEFT_MENU_OTHER: 'toggleLeftMenuOther',
    TOGGLE_NOTIFICATION: 'toggleNotification',
    UPDATE_TERMS_DECLINE_STATUS: 'updateTermsDeclineStatus',
};

export const action = {
    ACCEPT_TERMS: 'platform/AcceptTerms',
    ADD_FAVORITE_GAME: 'addFavoriteGame',
    GET_CASINO_GAMES: 'getCasinoGames',
    GET_CASINO_GAMES_STRAPI: 'getCasinoGamesStrapi',
    GET_CASINO_GAME: 'getCasinoGame',
    GET_CASINO_GAME_BRANDS: 'getCasinoGameBrands',
    GET_CASINO_GAME_BRAND: 'getCasinoGameBrand',
    GET_CASINO_GAME_CATEGORIES: 'getCasinoGameCategories',
    GET_CASINO_CATEGORY: 'getCasinoCategory',
    GET_CATEGORIES_LIST: 'getCategoriesList',
    GET_CATEGORY_ACTIVE_REGIONS: 'getCategoryActiveRegions',
    LOADING: 'loading',
    NOTIFY: 'Notify',
    OPEN_CASINO_GAME: 'openCasinoGame',
    SET_BETSLIP_STATE: 'setBetslipState',
    SET_SIDEBAR_STATE: 'setSidebarState',
    SET_TURNSTILE: 'ui/setTurnstile',
    TOGGLE_SIDEBAR: 'toggleSidebar',
};

export const getter = {
    GET_SORTED_CATEGORIES: 'getSortedCategories',
    GET_CHIPS: 'getChips',
    GET_CURRENT_CATEGORY: 'getCurrentCategory',
    GET_CURRENT_CATEGORY_ID: 'getCurrentCategoryId',
    GET_FAVORITE_GAMES_IDS: 'getFavoriteGamesIds',
    GET_GAMES: 'getGames',
    GET_GAME_CATEGORIES: 'getGameCategories',
    GET_GAME_BRANDS: 'getGameBrands',
    GET_GAME_BRAND: 'getGameBrand',
    GET_GAME_CATEGORY: 'getGameCategory',
    GET_FAVORITE_GAMES: 'getFavoriteGames',
    GET_GAME_CATEGORY_BY_NAMES: 'getGameCategoryByNames',
    GET_GAME_COLLECTION: 'getGameCollection',
    GET_GAME_COLLECTION_BY_CATEGORIES: 'getGameCollectionByCategories',
    GET_JACKPOT_POOL: 'getJackpotPool',
    GET_MENU_ITEM_BY_BETSLIP: 'getMenuItemByBetslip',
    GET_MY_BETS_MENU: 'getMyBetsMenu',
    GET_NOTIFICATION_TYPES: 'getNotificationTypes',
    GET_QUICK_MAIN_MENU: 'getQuickMainMenu',
    GET_UPCOMING_VIRTUAL_ROUND_INDEX: 'getUpcomingVirtualRoundIndex',
    IS_VIRTUAL_SPORT: 'isVirtualSport',
};

const snapshotToStorage = new SnapshotToStorage({
    isDev: IS_DEV,
    immediate(storeMutation) {
        switch (storeMutation.type) {
            case transMutation.SET_LANGUAGE:
                return true;
        }
        return false;
    },
    filter(storeMutation) {
        switch (storeMutation.type) {
            case transMutation.SET_LANGUAGE:
            case mutation.SET_CURRENT_CATEGORY:
            case mutation.SET_GAMES_SORT_BY:
            case mutation.UPDATE_TERMS_DECLINE_STATUS:
            case mutation.SET_CASINO_LAYOUT:
            case platformMutation.SET_CONFIG:
            case platformMutation.SET_AFFILIATE_ID:
            case auth.mutation.SET_OTP_LIMITS:
            case platformMutation.SET_AGI_SETTINGS:
            case platformMutation.SET_USER_STATE:
            case platformMutation.SET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT:
            case platformMutation.SET_CAMPAIGN_ELIGIBILITY:
            case platformMutation.SET_SURVEY:
            case messaging.mutation.SET_ONSITE_MESSAGES_AND_COUNT:
            case messaging.mutation.SET_MESSAGES:
            case auth.mutation.SET_PHONE_NUMBER:
            case auth.mutation.SET_OTP_TIMER:
            case auth.mutation.SET_AUTH:
            case auth.mutation.SET_OTP_ATTEMPTS:
            case auth.mutation.RESET_OTP_ATTEMPTS:
            case auth.mutation.SET_RESET_PASSWORD_TRACKING_ID:
            case sport.mutation.SET_JACKPOT_SELECTED:
            case sport.mutation.UPDATE_CASHOUT_POLLING:
            case betslip.mutation.CHANGE_BETSLIP_BET:
            case betslip.mutation.REMOVE_BETSLIP_BET:
            case betslip.mutation.REMOVE_ALL_BETSLIP_BETS:
            case betslip.mutation.SET_BETSLIP_STAKE:
            case betslip.mutation.RESET_BETSLIP_STAKE:
            case betslip.mutation.RESET_BETSLIP_STATUS:
            case betslip.mutation.SET_BETSLIP_ACCEPT_ANY_PRICE:
            case betslip.mutation.SET_VIRTUAL_BETSLIP_SEASON:
            case betslip.mutation.RESET_VIRTUAL_BETSLIP_SEASON:
            case betslip.mutation.UPDATE_SELECTED_PRICES:
            case betslip.mutation.ADD_BETSLIP_BET:
            case betslip.mutation.SET_BETSLIP_BETS:
            case paymentMutation.SET_FIRST_DEPOSIT_DATA:
            case paymentMutation.RESERVE_DEPOSIT_ID:
            case paymentMutation.SET_DEPOSIT_COMPONENT_IS_VISIBLE:
            case paymentMutation.RESET_DEPOSIT:
            case sport.mutation.SET_SAVED_EVENT_FILTERS:
                return true;
        }
        return false;
    },
    reducer(state) {
        return {
            sport: {
                betslip: {
                    selectedEventId: state.sport.betslip.selectedEventId,
                    selectedPriceId: state.sport.betslip.selectedPriceId,
                    regular: state.sport.betslip.regular,
                    virtual: state.sport.betslip.virtual,
                },
                jackpot: {
                    selected: state.sport.jackpot.selected,
                    uniqueTicketId: state.sport.jackpot.uniqueTicketId,
                },
                myBets: {
                    offerPolling: state.sport.myBets.offerPolling,
                },
                ui: {
                    savedEventFilters: state.sport.ui.savedEventFilters,
                },
            },
            currentCategoryId: state.currentCategoryId,
            platform: {
                config: state.platform.config,
                affiliate: state.platform.affiliate,
                settings: {
                    preference: {
                        first_bet: state.platform.settings.preference.first_bet || null,
                    },
                },
                content: {
                    isNeedToSendPaymentComponentVisibilityEvent: state.platform.content.isNeedToSendPaymentComponentVisibilityEvent,
                },
                auth: {
                    phoneNumber: state.platform.auth.phoneNumber,
                    otpTimer: state.platform.auth.otpTimer,
                    nextCheckBalanceTimestamp: state.platform.auth.nextCheckBalanceTimestamp,
                    accountBalance: state.platform.auth.accountBalance,
                    isBalanceReady: state.platform.auth.isBalanceReady,
                    otpAttempts: state.platform.auth.otpAttempts,
                    otpLimits: state.platform.auth.otpLimits,
                    nextOtpAttemptTimestamp: state.platform.auth.nextOtpAttemptTimestamp,
                    resetPasswordTrackingId: state.platform.auth.resetPasswordTrackingId,
                },
                campaignEligibility: state.platform.campaignEligibility,
                survey: state.platform.survey,
                messaging: {
                    nextCheckTimestamp: state.platform.messaging.nextCheckTimestamp,
                    notSeen: state.platform.messaging.notSeen,
                },
            },
            payment: {
                firstDeposit: state.payment.firstDeposit,
                /**
                 * @deprecated since v3.14.x - use `firstDeposit` instead.
                 */
                lastDepositProvider: state.payment.lastDepositProvider,
                reservedDeposit: state.payment.reservedDeposit,
                deposit: {
                    providers: {
                        isDepositComponentVisible: state.payment.deposit.providers.isDepositComponentVisible,
                    },
                },
            },
            translations: {
                language: state.translations.language,
                persist: state.translations.persist,
            },
            isTermsDeclined: state.isTermsDeclined || false,
            casino: {
                layout: state.casino.layout || toolbarIconType.TILE,
            },
        };
    },
});

export default new Vuex.Store({
    state: {
        title: 'State',
        menus: MENUS,
        notification: {
            notifications: [],
            trigger: false,
        },
        countries: {},
        currentCategoryId: null,
        currentTournaments: [],
        ui: {
            liveRefreshRate: 3,
            sidebarOpen: false,
            betslipOpen: false,
            topMenuOpen: false,
            bottomMenuOpen: false,
            mobileSearchOpen: false,
            leftMenuExpandedCountries: [], // TODO: merge with menus state
            showLeftMenuOther: false, // TODO: merge with menus state,
            turnstile: [],
        },
        showTopMenu: false,
        casinoGames: {},
        wazdanGames: {},
        games: {},
        gamesCollection: [], // remove for old casino
        gamesSortBy: sortType.POPULAR, // remove for old casino
        gamesById: {},
        gamesBySlug: {},
        gameCategories: [],
        gameBrands: [],
        gameBrandsById: {},
        gameCategoriesById: {},
        gameLaunchError: null,
        casino: {
            layout: toolbarIconType.TILE,
            favorites: [],
            error: null,
        },
        isTermsDeclined: false,
    },
    getters: {
        [getter.GET_GAME_COLLECTION]: ({ gamesCollection }) => {
            return gamesCollection
                .filter((game) => game.enabled)
                .sort((a, b) => {
                    if (a.position > b.position) return 1;
                    if (a.position < b.position) return -1;
                    return 0;
                });
        },
        [getter.GET_FAVORITE_GAMES]: ({ platform }, getters) => {
            const sortedGameCollection = getters[getter.GET_GAME_COLLECTION];
            const favoriteGamesIds = [...getters[getter.GET_FAVORITE_GAMES_IDS].old].reverse();
            return favoriteGamesIds.map((favId) => sortedGameCollection.find(({ id }) => String(id) === String(favId)));
        },
        [getter.GET_GAME_COLLECTION_BY_CATEGORIES]: ({ gamesCollection }, getters) => {
            const sortedGameCollection = getters[getter.GET_GAME_COLLECTION];
            const filteredGameCollection = {};

            sortedGameCollection.forEach((game) => {
                game.categories.forEach((category) => {
                    if (!category.length) return;
                    filteredGameCollection[category] = [...(filteredGameCollection[category] || []), game];
                });
            });
            return filteredGameCollection;
        },
        [getter.GET_GAME_CATEGORY_BY_NAMES]: (state, getters) => {
            const { brandGamingIntegration } = getters[platformGetter.GET_SETTINGS];
            const language = getters[transGetter.GET_SELECTED_LANGUAGE];
            const categoriesByNames = {};

            brandGamingIntegration &&
                brandGamingIntegration.categories.forEach((category) => {
                    categoriesByNames[category.name] = {
                        name: category?.name,
                        text: category?.translations[language],
                        position: category?.position,
                    };
                });
            return categoriesByNames;
        },
        getNotificationTypes: (state) => state.notification.notifications.map((item) => item.type),
        [getter.GET_GAMES]: ({ games }) => {
            return games.data;
        },
        [getter.GET_GAME_CATEGORIES]: ({ gameCategories }) => {
            return gameCategories;
        },
        [getter.GET_GAME_CATEGORY]: ({ gameCategoriesById }) => {
            return gameCategoriesById;
        },
        [getter.GET_GAME_BRANDS]: ({ gameBrands }) => {
            return gameBrands;
        },
        [getter.GET_GAME_BRAND]: ({ gameBrandsById }) => {
            return gameBrandsById;
        },
        [getter.GET_FAVORITE_GAMES_IDS]: ({ platform }, getters) => {
            if (!getters[auth.getter.IS_AUTHENTICATED]) {
                return {
                    old: [],
                    strapi: [],
                };
            }
            const { favorite_casino_games_ids: favGamesIds, favorite_strapi_casino_games_ids: strapiFavGamesIds } =
                getters[platformGetter.GET_PREFERENCE];
            let parsedFavGames = `${favGamesIds}`
                .split(',')
                .map((id) => parseInt(id))
                .filter((id) => isNumber(id))
                .reverse();
            let parsedStrapiFavGames = [];
            try {
                const tempStrapi = JSON.parse(strapiFavGamesIds);
                if (tempStrapi && tempStrapi.length > 0) {
                    parsedStrapiFavGames = tempStrapi;
                }
            } catch (e) {
                console.error(
                    `Parsing \`favorite_strapi_casino_games_ids\` from GET_PREFERENCE getter failed. The content was: ${strapiFavGamesIds}`
                );
            }
            return {
                old: parsedFavGames,
                strapi: parsedStrapiFavGames,
            };
        },
        [getter.GET_MY_BETS_MENU]: ({ menus, platform }) => {
            return menus.myBets
                .map((menu) => ({ ...menu, text: Vue.$t(menu.tKey) }))
                .filter((menu) => !menu.enabler || helper.pathChecker(menu.enabler, platform.settings));
        },
        [getter.GET_MENU_ITEM_BY_BETSLIP]:
            (state, getters) =>
            ({ betslipType = '', status = '' }) => {
                const myBetsMenu = getters[getter.GET_MY_BETS_MENU];
                const defaultItem = myBetsMenu.find((item) => item.default);
                switch (betslipType) {
                    case betType.REAL:
                        if (status === betStatus.PENDING) {
                            return myBetsMenu.find((item) => item.key === myBetsType.PENDING) || defaultItem;
                        }
                        return myBetsMenu.find((item) => item.key === myBetsType.SETTLED) || defaultItem;
                    case betType.VIRTUAL:
                        return myBetsMenu.find((item) => item.key === myBetsType.VIRTUAL) || defaultItem;
                    case betType.JACKPOT:
                        return myBetsMenu.find((item) => item.key === myBetsType.JACKPOT) || defaultItem;
                }

                return defaultItem;
            },
        [getter.GET_SORTED_CATEGORIES]: (state, getters) => {
            const rawCategories = getters[platformGetter.GET_CATEGORIES];
            return [...rawCategories].sort((a, b) => a.priority - b.priority);
        },
        [getter.GET_CURRENT_CATEGORY_ID]: (state, getters) => {
            const stateCategoryId = state.currentCategoryId;
            const stateCategoryData = getters[getter.GET_SORTED_CATEGORIES].find(
                (category) => String(category.id) === String(stateCategoryId)
            );

            if (stateCategoryId && stateCategoryData) {
                return stateCategoryId;
            }

            return getters[getter.GET_SORTED_CATEGORIES][0]?.id;
        },
        [getter.GET_CURRENT_CATEGORY]: (state, getters) => {
            const currentCategoryId = getters[getter.GET_CURRENT_CATEGORY_ID];
            const categories = getters[getter.GET_SORTED_CATEGORIES];
            return categories.find((category) => String(category.id) === String(currentCategoryId)) || {};
        },
        [getter.GET_QUICK_MAIN_MENU]: (state) => {
            return state.menus.quickMainMenu;
        },
    },
    mutations: {
        [mutation.SET_TURNSTILE](state, newValue) {
            state.ui.turnstile = getComponentsWithCaptcha(newValue);
        },
        [mutation.SET_CURRENT_CATEGORY](state, newValue) {
            state.currentCategoryId = newValue;
        },
        [mutation.SET_TOURNAMENTS](state, newValue) {
            state.currentTournaments = newValue;
        },
        [mutation.SET_COUNTRIES](state, newValue) {
            state.countries = newValue;
        },
        setBetslipState(state, value) {
            state.ui.betslipOpen = value;
        },
        toggleBetslipState(state) {
            state.ui.betslipOpen = !state.ui.betslipOpen;
        },
        toggleSidebar(state) {
            state.ui.sidebarOpen = !state.ui.sidebarOpen;
        },
        setSidebarState(state, value) {
            state.ui.sidebarOpen = value;
        },
        toggleMobileSearch(state) {
            state.ui.mobileSearchOpen = !state.ui.mobileSearchOpen;
            state.ui.topMenuOpen = false;
        },
        closeMobileSearch(state) {
            state.ui.mobileSearchOpen = false;
        },
        setTopMenuState(state, value) {
            state.ui.topMenuOpen = value;
        },
        setBottomMenuState(state, value) {
            state.ui.bottomMenuOpen = value;
        },
        [mutation.CLOSE_TOP_MENU](state) {
            state.ui.topMenuOpen = false;
        },
        [mutation.SET_GAMES_COLLECTION](state, collection) {
            state.gamesCollection = collection;
        },
        [mutation.SET_GAMES_SORT_BY](state, value) {
            state.gamesSortBy = value !== state.gamesSortBy ? value : null;
        },
        [mutation.SET_GAMES](state, { filterProps, start, limit, data, error, isLoading, sortBy }) {
            const key = getKeyForGamesInCategory({
                filterProps,
                start,
                limit,
                sortBy,
            });
            state.games = { ...state.games, [key]: { data, error, isLoading } };
            if (Array.isArray(data)) {
                const games = {};
                const gamesSlug = {};
                data.forEach((game) => {
                    const gameData = { data: game, isLoading: false, error: null };
                    games[game.id] = gameData;
                    gamesSlug[game.slug] = gameData;
                });
                state.gamesById = { ...state.gamesById, ...games };
                state.gamesBySlug = { ...state.gamesBySlug, ...gamesSlug };
            }
        },
        [mutation.SET_GAME](state, { gameSlug, gameId, data, error, isLoading }) {
            if (gameId) {
                state.gamesById = { ...state.gamesById, [gameId]: { data, error, isLoading } };
            }
            state.gamesBySlug = { ...state.gamesBySlug, [gameSlug]: { data, error, isLoading } };
        },
        [mutation.SET_GAME_CATEGORIES](state, collection) {
            const categories = {};
            collection.forEach((category) => {
                categories[category.id] = { data: category, isLoading: false, error: null };
            });
            state.gameCategoriesById = { ...state.gameCategoriesById, ...categories };
            state.gameCategories = collection;
        },
        [mutation.SET_GAME_BRANDS](state, collection) {
            const brands = {};
            collection.data.forEach((brand) => {
                brands[brand.id] = { data: brand, isLoading: false, error: null };
            });
            state.gameBrandsById = { ...state.gameBrandsById, ...brands };
            state.gameBrands = collection.data;
        },
        [mutation.SET_GAME_BRAND](state, { brandId, data, error, isLoading }) {
            state.gameBrandsById = { ...state.gameBrandsById, [brandId]: { data, error, isLoading } };
        },
        [mutation.SET_GAME_CATEGORY](state, { categoryId, data, error, isLoading }) {
            state.gameCategoriesById = { ...state.gameCategoriesById, [categoryId]: { data, error, isLoading } };
        },
        [mutation.SET_GAME_LAUNCH_ERROR](state, value = null) {
            state.gameLaunchError = value;
        },
        [mutation.TOGGLE_LEFT_MENU_COUNTRY](state, countryId) {
            const expandedCountries = state.ui.leftMenuExpandedCountries;
            const index = expandedCountries.indexOf(countryId);
            if (index < 0) {
                Vue.set(expandedCountries, expandedCountries.length, countryId);
            } else {
                Vue.delete(expandedCountries, index);
            }
        },
        [mutation.TOGGLE_LEFT_MENU_OTHER](state) {
            state.ui.showLeftMenuOther = !state.ui.showLeftMenuOther;
        },
        [mutation.ADD_NOTIFICATION](state, { type, data, status = 'success', excludedRoutes = [] }) {
            state.notification.notifications.push({ type, data, status, excludedRoutes });
            if (data && data.trigger) {
                state.notification.trigger = true;
            }
        },
        [mutation.CLEAR_NOTIFICATIONS](state) {
            state.notification.notifications = [];
            state.notification.trigger = false;
        },
        [mutation.TOGGLE_NOTIFICATION](state) {
            state.notification.trigger = !state.notification.trigger;
        },
        [mutation.SET_CASINO_FAVORITES](state, value) {
            state.casino.favorites = value;
        },
        [mutation.SET_CASINO_LAYOUT](state, value) {
            state.casino.layout = value;
        },
        [mutation.UPDATE_TERMS_DECLINE_STATUS](state, payload) {
            state.isTermsDeclined = payload;
        },
    },
    actions: {
        [action.GET_CASINO_GAMES]: actionLoader(action.GET_CASINO_GAMES, ({ commit, getters }) => {
            return Vue.$http
                .get(platformEndpoints.casino.getCasinoGames)
                .then((response) => response.data.result)
                .then((games) => {
                    const enabledGameCategoriesList = Object.keys(getters[getter.GET_GAME_CATEGORY_BY_NAMES] || {});

                    const collection = games.map((game) => ({
                        ...game,
                        gameId: `${getObjectField(game, 'nameShort') || game.gameId || game.name}`,
                        categories: (getObjectField(game, 'lobbyCategories') ?? []).filter((category) =>
                            enabledGameCategoriesList.includes(category)
                        ),
                    }));
                    commit(mutation.SET_GAMES_COLLECTION, collection);
                })
                .catch((error) => {
                    commit(mutation.SET_GAME_CASINO_LOAD_ERROR, error.message || error);
                });
        }),
        [action.SET_TURNSTILE]({ commit }, value) {
            commit(mutation.SET_TURNSTILE, value);
        },
        [action.GET_CATEGORY_ACTIVE_REGIONS]({ commit, rootGetters, dispatch }, categoryId) {
            const competitionIcons = rootGetters[platformGetter.GET_JURISDICTION_CONFIG].globalConfig.competitionIcons;

            function getIconUrl({ id }) {
                if (id in competitionIcons) {
                    return competitionIcons[id];
                }

                return '';
            }

            return Vue.$http
                .get(sportEndpoints.pricing.getCategoryActiveRegions + `/${categoryId}`)
                .then(({ data }) => {
                    const { withRegions, onlyMeta } = data;
                    const { liveEventCount = 0, upcomingEventCount = 0, boostedEventCount = 0, regions = [] } = withRegions[0] ?? {};
                    const otherCategoriesLiveEventCount = onlyMeta.reduce((acc, { liveEventCount }) => acc + liveEventCount, 0);
                    const upcomingEventCountPerCategory = getEventTypeCountPerCategory(onlyMeta, 'upcomingEventCount');
                    const boostedEventCountPerCategory = getEventTypeCountPerCategory(onlyMeta, 'boostedEventCount');
                    const tournaments = [];

                    const sortedCountries = regions
                        .map((country) => {
                            const currentCountry = {
                                region: country.region.name,
                                regionSlug: country.region.slug,
                                parentRegionSlug: country.region.parentSlug,
                            };
                            let countryEventCount = 0;
                            let countryPriority = 0;
                            const currentCompetitions = [];
                            if (country.competitions.length) {
                                country.competitions.forEach(({ competition, eventCount }) => {
                                    const competitionData = {
                                        ...competition,
                                        url: getIconUrl(competition),
                                        eventCount: eventCount,
                                    };
                                    if (countryPriority < competition.priority) {
                                        countryPriority = competition.priority;
                                    }
                                    if (competition.priority) {
                                        tournaments.push({
                                            ...competitionData,
                                            ...currentCountry,
                                        });
                                    }
                                    countryEventCount += eventCount;
                                    currentCompetitions.push(competitionData);
                                });
                            }
                            currentCompetitions.sort((a, b) => b.priority - a.priority);
                            return {
                                ...country,
                                eventCount: countryEventCount,
                                priority: countryPriority,
                                competitions: currentCompetitions,
                            };
                        })
                        .sort((a, b) => a.region.name.localeCompare(b.region.name));
                    const updatedTournaments = tournaments.flat().sort((a, b) => b.priority - a.priority);
                    commit(mutation.SET_TOURNAMENTS, updatedTournaments);
                    commit(mutation.SET_COUNTRIES, sortedCountries);
                    commit(sport.mutation.UPDATE_SPORTS, {
                        liveEventsCount: otherCategoriesLiveEventCount + liveEventCount,
                        boostedEventsCount: { ...boostedEventCountPerCategory, [categoryId]: boostedEventCount },
                        moreEvents: { ...upcomingEventCountPerCategory, [categoryId]: upcomingEventCount },
                    });
                })
                .catch((error) => console.error(`${action.GET_CATEGORY_ACTIVE_REGIONS} Response Error`, [error, categoryId]));
        },
        [action.TOGGLE_SIDEBAR]({ commit }) {
            commit('toggleSidebar');
        },
        [action.SET_SIDEBAR_STATE]({ commit }, value) {
            commit('setSidebarState', value);
        },
        toggleBetslipState({ commit, dispatch }) {
            dispatch(betslip.action.SELECT_BETSLIP_TYPE);
            commit('toggleBetslipState');
        },
        [action.SET_BETSLIP_STATE]({ commit, dispatch }, isOpen) {
            dispatch(betslip.action.SELECT_BETSLIP_TYPE);
            commit('setBetslipState', isOpen);
        },
        [action.GET_CASINO_GAME_CATEGORIES]: actionLoader(
            action.GET_CASINO_GAME_CATEGORIES,
            ({ commit, dispatch, getters, rootGetters }, { includeDrafts }) => {
                const locale = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE] || env.VUE_APP_LANGUAGE;
                const query = buildCasinoCategoriesStrapiQuery({
                    includeDrafts,
                    countryCode: getters[platformGetter.GET_COUNTRY],
                });

                return dispatch(
                    coreAction.LOADER,
                    [
                        getters[getter.GET_GAME_CATEGORIES].isLoading,
                        (resolve, reject) =>
                            Vue.$http
                                .get(`${strapi.getCasinoGamesCategories}?${query}`)
                                .then(({ data }) => {
                                    let responseData = data.data.map(flatDataAttributes);
                                    responseData = getModelsWithLocalisedName(responseData, locale, 'name');
                                    responseData = getSortedCasinoCategories(responseData);
                                    return responseData;
                                })
                                .then((categories) => {
                                    commit(mutation.SET_GAME_CATEGORIES, categories);
                                    resolve(categories);
                                })
                                .catch((error) => {
                                    reject(error.message || error);
                                }),
                    ],
                    { root: true }
                );
            }
        ),
        [action.GET_CASINO_GAME_BRANDS]: actionLoader(
            action.GET_CASINO_GAME_BRANDS,
            ({ commit, dispatch, getters, rootGetters }, { includeDrafts }) => {
                const locale = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE] || env.VUE_APP_LANGUAGE;
                const query = buildCasinoGameBrandsStrapiQuery({
                    includeDrafts,
                    locale,
                    countryCode: getters[platformGetter.GET_COUNTRY],
                });

                return dispatch(
                    coreAction.LOADER,
                    [
                        getters[getter.GET_GAME_BRANDS],
                        (resolve, reject) =>
                            Vue.$http
                                .get(`${strapi.getCasinoGamesBrands}?${query}`)
                                .then((response) => response.data)
                                .then((brands) => {
                                    brands.data = brands.data.filter((b) => b.attributes.logo.data !== null); // Can be removed after we upgrade Strapi to >= 4.23.0 https://github.com/strapi/strapi/issues/12225
                                    commit(mutation.SET_GAME_BRANDS, brands);
                                    resolve(brands);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    reject(error.message || error);
                                }),
                    ],
                    { root: true }
                );
            }
        ),
        [action.GET_CASINO_GAME_BRAND]: actionLoader(
            action.GET_CASINO_GAME_BRAND,
            ({ commit, dispatch, getters, rootGetters }, { brandId, includeDrafts }) => {
                const locale = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE] || env.VUE_APP_LANGUAGE;
                const query = buildCasinoGameBrandStrapiQuery({
                    brandId,
                    countryCode: getters[platformGetter.GET_COUNTRY],
                    locale,
                    includeDrafts,
                });

                commit(mutation.SET_GAME_BRAND, { brandId, data: null, isLoading: true, error: null });
                return Vue.$http
                    .get(`${strapi.getCasinoGamesBrands}/${brandId}?${query}`)
                    .then(({ data }) => {
                        commit(mutation.SET_GAME_BRAND, { brandId, data: data.data, isLoading: false, error: null });
                    })
                    .catch((strapiError) => {
                        commit(mutation.SET_GAME_BRAND, {
                            brandId,
                            data: null,
                            isLoading: false,
                            error: strapiError.message || strapiError,
                        });
                        Vue.$sentry.withScope((scope) => {
                            scope.setExtras({ error: strapiError });
                            scope.setTag('statusCode', strapiError.statusCode);
                            scope.setTag('scope', 'strapi');
                            scope.setTag('config', 'unavailable');
                            scope.setLevel('fatal');
                            Vue.$sentry.captureMessage('FATAL_ERROR_STRAPI_CASINO_BRANDS');
                        });
                    });
            }
        ),
        [action.GET_CASINO_GAMES_STRAPI]: actionLoader(
            action.GET_CASINO_GAMES_STRAPI,
            ({ commit, getters, rootGetters }, { filterProps, start, limit, sortBy, includeDrafts }) => {
                let adjustedStart = start;
                let sort = 'globalPosition:asc'; // sortBy: popular

                if (sortBy === 'name:asc') {
                    sort = 'name:asc';
                } else if (sortBy === 'name:desc') {
                    sort = 'name:desc';
                } else if (
                    (sortBy === sortType.FAVOURITES_ADDED_ASC || sortBy === sortType.FAVOURITES_ADDED_DESC) &&
                    filterProps.favourites
                ) {
                    // Interesting case, if we order by order of the favs array, then we paginate resources on frontend side,
                    // so we ask only always for first page, hence we adjust the start param to be 0. Then we
                    // slice the favsIDs to return only what is of interest to us in the filtering part of code
                    adjustedStart = 0;
                }
                const filters = buildCasinoGamesFilters({ filterProps, includeDrafts });
                let filterFavourites = buildCasinoGamesFavouritesFilter({ filterProps, sortBy, start, limit });
                const locale = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE] || env.VUE_APP_LANGUAGE;
                const query = buildCasinoGamesStrapiQuery({
                    includeDrafts,
                    start: adjustedStart,
                    limit,
                    filters,
                    sort,
                    locale,
                    filterFavourites,
                });
                commit(mutation.SET_GAMES, {
                    filterProps,
                    start,
                    limit,
                    sortBy,
                    data: null,
                    isLoading: true,
                    error: null,
                });

                return Vue.$http
                    .get(`${strapi.getCasinoGames}?${query}`)
                    .then(({ data }) => {
                        let responseData = data.data.map(flatDataAttributes);
                        let games = getGamesWithLocalizedRibbonName(responseData, locale);
                        if (
                            (sortBy === sortType.FAVOURITES_ADDED_ASC || sortBy === sortType.FAVOURITES_ADDED_DESC) &&
                            filterProps.favourites
                        ) {
                            games = sortCasinoGamesForFavourites({ sortBy, games, filterProps });
                        }
                        commit(mutation.SET_GAMES, {
                            filterProps,
                            start,
                            limit,
                            data: games,
                            sortBy,
                            isLoading: false,
                            error: null,
                        });
                    })
                    .catch((strapiError) => {
                        commit(mutation.SET_GAMES, {
                            filterProps,
                            start,
                            limit,
                            sortBy,
                            data: null,
                            isLoading: false,
                            error: strapiError.message || strapiError,
                        });
                        Vue.$sentry.withScope((scope) => {
                            scope.setExtras({ error: strapiError });
                            scope.setTag('statusCode', strapiError.statusCode);
                            scope.setTag('scope', 'strapi');
                            scope.setTag('config', 'unavailable');
                            scope.setLevel('fatal');
                            Vue.$sentry.captureMessage('FATAL_ERROR_STRAPI_CASINO_GAMES');
                        });
                    });
            }
        ),
        [action.GET_CASINO_CATEGORY]: actionLoader(
            action.GET_CASINO_CATEGORY,
            ({ commit, dispatch, getters, rootGetters }, { categoryId, includeDrafts }) => {
                const locale = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE] || env.VUE_APP_LANGUAGE;
                const query = buildCasinoCategoryStrapiQuery({
                    includeDrafts,
                });
                commit(mutation.SET_GAME_CATEGORY, { categoryId, data: null, isLoading: true, error: null });
                return Vue.$http
                    .get(`${strapi.getCasinoGamesCategories}/${categoryId}?${query}`)
                    .then(({ data }) => {
                        let gameCategories = flatDataAttributes(data.data);
                        gameCategories = getModelWithLocalisedName(gameCategories, locale, 'name');
                        commit(mutation.SET_GAME_CATEGORY, {
                            categoryId,
                            data: gameCategories,
                            isLoading: false,
                            error: null,
                        });
                    })
                    .catch((strapiError) => {
                        commit(mutation.SET_GAME_CATEGORY, {
                            categoryId,
                            data: null,
                            isLoading: false,
                            error: strapiError.message || strapiError,
                        });
                        Vue.$sentry.withScope((scope) => {
                            scope.setExtras({ error: strapiError });
                            scope.setTag('statusCode', strapiError.statusCode);
                            scope.setTag('scope', 'strapi');
                            scope.setTag('config', 'unavailable');
                            scope.setLevel('fatal');
                            Vue.$sentry.captureMessage('FATAL_ERROR_STRAPI_CASINO_GAMES');
                        });
                    });
            }
        ),
        [action.GET_CASINO_GAME]: actionLoader(
            action.GET_CASINO_GAME,
            ({ commit, dispatch, getters, rootGetters }, { gameSlug, includeDrafts }) => {
                const locale = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE] || env.VUE_APP_LANGUAGE;
                const query = buildCasinoGameStrapiQuery({
                    includeDrafts,
                    gameSlug,
                });
                commit(mutation.SET_GAME, { gameSlug, data: null, isLoading: true, error: null });
                return Vue.$http
                    .get(`${strapi.getCasinoGames}?${query}`)
                    .then(({ data }) => {
                        let game = flatDataAttributes(data.data[0]);
                        if (!game) {
                            commit(mutation.SET_GAME, {
                                gameSlug,
                                data: null,
                                isLoading: false,
                                error: new Error('Game not found in Strapi'),
                            });
                            return;
                        }
                        game = getGameWithLocalizedRibbonName(game, locale);
                        commit(mutation.SET_GAME, {
                            gameSlug,
                            gameId: `${game.id}`,
                            data: game,
                            isLoading: false,
                            error: null,
                        });
                    })
                    .catch((strapiError) => {
                        commit(mutation.SET_GAME, {
                            gameSlug,
                            data: null,
                            isLoading: false,
                            error: strapiError.message || strapiError,
                        });
                        Vue.$sentry.withScope((scope) => {
                            scope.setExtras({ error: strapiError });
                            scope.setTag('statusCode', strapiError.statusCode);
                            scope.setTag('scope', 'strapi');
                            scope.setTag('config', 'unavailable');
                            scope.setLevel('fatal');
                            Vue.$sentry.captureMessage('FATAL_ERROR_STRAPI_CASINO_GAME');
                        });
                    });
            }
        ),
        [action.OPEN_CASINO_GAME]: actionLoader(action.LOADING, ({ commit, getters }, { name, provider, providerGameId }) => {
            const language = getters[transGetter.GET_SELECTED_LANGUAGE];

            return Vue.$http
                .post(platformEndpoints.casino.casinoLauncherV2, {
                    provider: provider,
                    mode: casinoGameMode.real,
                    lobbyUrl: window.location.href,
                    providerGameId,
                    language,
                })
                .then(({ data }) => {
                    Vue.$gtm.query({
                        event: Vue.$gtm.makeValidEventName(
                            `casino_lobby_${provider === casinoProviderType.NATIVE ? 'native' : provider}_game_launch`
                        ),
                        game_name: name,
                    });
                    window.location.href = data.launchUrl || '';
                })
                .catch(({ errorCode }) => {
                    commit(mutation.SET_GAME_LAUNCH_ERROR, { gameId: name, errorCode });
                });
        }),
        [action.ADD_FAVORITE_GAME]({ dispatch, commit, getters }, favorites) {
            const currentFavs = getters[getter.GET_FAVORITE_GAMES_IDS];
            const savedStrapi = JSON.stringify(currentFavs.strapi);
            const toSaveStrapi = JSON.stringify(favorites.strapi);
            if (savedStrapi.localeCompare(toSaveStrapi) !== 0) {
                commit(platformMutation.SET_FAVORITE_CASINO_GAME, toSaveStrapi);
                dispatch(platformAction.PUT_DERIVED_DATA, {
                    key: 'favorite_strapi_casino_games_ids',
                    value: toSaveStrapi,
                }).catch(({ errorCode }) => {
                    commit(mutation.SET_GAME_LAUNCH_ERROR, { value: toSaveStrapi, errorCode });
                });
            }

            const savedOld = currentFavs.old.join(',');
            const toSaveOld = favorites.old.join(',');
            if (savedOld.localeCompare(toSaveOld) !== 0) {
                commit(platformMutation.SET_FAVORITE_CASINO_GAME_OLD, toSaveOld);
                dispatch(platformAction.PUT_DERIVED_DATA, {
                    key: 'favorite_casino_games_ids',
                    value: toSaveOld !== '' ? toSaveOld : 'null',
                }).catch(({ errorCode }) => {
                    commit(mutation.SET_GAME_LAUNCH_ERROR, { value: toSaveOld, errorCode });
                });
            }
        },
        [action.NOTIFY]({ commit }, payload) {
            commit(mutation.ADD_NOTIFICATION, payload);
        },
        [action.ACCEPT_TERMS]: ({ dispatch }, payload) => {
            dispatch(platformAction.PUT_DERIVED_DATA, payload).then(() => {
                dispatch(platformAction.LOAD_AGI_SETTINGS);
            });
        },
    },
    modules: {
        core,
        platform: platformStore,
        sport: sportStore,
        payment,
        translations,
    },
    strict: IS_DEV,
    plugins: [snapshotToStorage.plugin],
});

function getEventTypeCountPerCategory(metaData = [], eventType) {
    return metaData.reduce((acc, category) => ({ ...acc, [category.category.id]: category[eventType] }), {});
}
