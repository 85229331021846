var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown"},[_c('span',{directives:[{name:"click-outside",rawName:"v-click-outside:[clickOutside]",value:(_vm.handleOutside),expression:"handleOutside",arg:_vm.clickOutside}],staticClass:"dropdown-item pointer",on:{"click":function($event){return _vm.toggleOpen()}}},[(_vm.iconId)?_c('SvgIcon',{staticClass:"dropdown-icon icon-size-medium",attrs:{"iconId":_vm.iconId}}):_vm._e(),_vm._t("default",function(){return [(_vm.label)?_c('span',{staticClass:"label",class:{ underline: _vm.isLabelUnderline },attrs:{"id":(_vm.name + "-dropdown-open")}},[_vm._v(_vm._s(_vm.label))]):_vm._e()]})],2),(_vm.isOpen)?_c('div',{staticClass:"dropdown-menu",class:{ 'right-sided': _vm.rightSided },style:({ minWidth: (_vm.menuWidth + "px") })},_vm._l((_vm.items),function(ref,index){
var key = ref.key;
var value = ref.value;
return _c('MenuItem',{key:("-dropdown-item-" + index),attrs:{"selected":value === _vm.selected,"item":{
                name: _vm.name || '',
                selector: key,
                text: key || value,
                control: { icon: 'icon-check', iconSize: 'icon-size-medium', iconDisabled: value !== _vm.selected },
            }},nativeOn:{"click":function($event){return _vm.$emit('value', value)}}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }