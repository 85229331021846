<template>
    <div class="main-footer">
        <div v-if="isAuthenticated && !isMyBetsRoute" class="cta-container">
            <router-link v-if="isHelpEnabledAuthenticated" class="button button-accent upperCase" :to="{ path: '/help' }">
                {{ $t('ui.help.button') }}
            </router-link>
            <router-link v-else tag="button" class="button button-accent upperCase" :to="{ path: '/bets' }">
                {{ $t('ui.myBets.title') }}
            </router-link>
        </div>
        <div v-if="!isAuthenticated" class="cta-container">
            <router-link v-if="isHelpEnabled" class="button button-accent upperCase" :to="{ path: '/help' }">
                {{ $t('ui.help.button') }}
            </router-link>
            <router-link v-else class="button button-accent upperCase" id="join-now-bottom" :to="{ path: '/join-now' }">
                {{ $t('ui.common.joinNow') }}
            </router-link>
        </div>
        <div class="footer-menu action-menu">
            <LanguageSelector class="footer-language" :horizontal="!$mq.isVerySmall" parent="footer" dark />
            <span
                class="footer-category footer-link"
                :key="'footer-country-' + category.id"
                v-for="category in categories"
                @click="categoryClick(category)"
            >
                {{ category.name }}
            </span>
        </div>
        <div class="footer-social">
            <a class="icons" v-for="(menuItem, index) in socialLinks" :key="index" :href="menuItem.url" target="_blank">
                <SvgIcon class="icon-footer" :iconId="menuItem.icon"></SvgIcon>
            </a>
        </div>
        <ul v-if="!hasStrapiFooterMenu" class="footer-user-menu">
            <li v-for="(menuItem, index) in footerUserMenu" :key="index">
                <router-link class="footer-link" :to="{ path: menuItem.url }">
                    {{ $t(menuItem.key) }}
                </router-link>
            </li>
        </ul>
        <ul class="footer-menu">
            <li v-for="(menuItem, index) in footerMenu" :key="'footer-menu-' + index">
                <router-link
                    class="footer-link"
                    :to="{ path: menuItem.url }"
                    v-bind="{
                        ...(menuItem.disableHighlight && { 'active-class': '', 'exact-active-class': '' }),
                    }"
                >
                    {{ hasStrapiFooterMenu ? menuItem.name : $t(menuItem.key) }}
                </router-link>
                {{ hasStrapiFooterMenu ? '' : menuItem.Url }}
            </li>
        </ul>
        <router-link :to="{ name: 'Homepage' }">
            <SvgIcon iconId="logo" iconFallback="logo" class="main-footer-logo" />
        </router-link>
        <div v-if="country" class="jurisdiction-selector">
            <CountryFlag />
            <span class="country-name">{{ jurisdictionName }}</span>
            <span class="footer-category footer-link" @click="redirectToLandingPage">{{ $t('ui.statement.change') }}</span>
        </div>
        <div class="legal-text" v-if="footerContent">
            <DynamicComponent
                :key="'legal-' + index"
                v-for="(item, index) in footerContent.content"
                :data="item"
                :path="'content'"
                :isDarkBackground="true"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { android, deviceType, ENV, helper } from '@agi.packages/core';
import { getter as platformGetter, auth } from '@agi.packages/platform';
import { DynamicComponent, CountryFlag } from '@agi.packages/platform/components';
import { action, mutation, getter as generalGetter } from '@/store/store';

import LanguageSelector from './LanguageSelector';
import { routeName } from '@/router/const-name';

export default {
    name: 'Footer',
    components: { DynamicComponent, LanguageSelector, CountryFlag },
    computed: {
        ...mapState({
            mediaLinks: (state) => state.platform.settings?.brand?.media || {},
            hasStrapiFooterMenu: (state) => state.platform.config?.footerMenu?.isFooterMenuEnabled,
            footerMenu(state) {
                if (this.hasStrapiFooterMenu) {
                    return helper.processStrapiMenuLinks({
                        links: helper.getObjectField(state.platform.config.footerMenu, 'links', []),
                        userStatus: this.currentUserStatus,
                        isAuthenticated: this.isAuthenticated,
                        transformer: (link) => ({ ...link, url: link.fullPath }),
                        router: this.$router,
                    });
                }
                return state.menus.footer.filter((menu) => !menu.enabler || helper.pathChecker(menu.enabler, this.settings));
            },
            footerUserMenu(state) {
                return state.menus.footerUserMenu.filter((item) => this.isAuthRequired(item));
            },
            footerSocialMenu: (state) => state.menus.footerSocialMenu,
        }),
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            settings: platformGetter.GET_SETTINGS,
            country: platformGetter.GET_COUNTRY,
            cmsPages: platformGetter.GET_PAGES,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
            categories: generalGetter.GET_SORTED_CATEGORIES,
        }),
        page() {
            const pathMatch = this.$route.params.pathMatch || this.$route.path;
            const pagePath = pathMatch.split('/').pop() || '';
            return this.cmsPages[pagePath] || {};
        },
        socialLinks() {
            return this.footerSocialMenu
                .filter((item) => this.mediaLinks[item.url])
                .map((item) => ({ ...item, url: this.mediaLinks[item.url] }));
        },
        isMyBetsRoute() {
            return this.$route.name === routeName.MY_BETS;
        },
        isEnabledCmsHelpButton() {
            return this.page?.isEnabledHelpButton;
        },
        // TODO: add global CTA to meta data in router https://aliengain.atlassian.net/browse/BP-19334
        isHelpEnabled() {
            return [
                routeName.SIGNUP,
                routeName.ACCOUNT_VERIFICATION,
                routeName.RESET,
                routeName.SET_PASSWORD,
                routeName.JOIN_NOW,
                routeName.LOGIN,
                routeName.RESET_PASSWORD,
            ].includes(this.$route.name);
        },
        // TODO: add global CTA to meta data in router https://aliengain.atlassian.net/browse/BP-19334
        isHelpEnabledAuthenticated() {
            return [routeName.WITHDRAW, routeName.MY_ACCOUNT].includes(this.$route.name) || this.isEnabledCmsHelpButton;
        },
        footerContent() {
            return this.$store.getters[platformGetter.GET_CONTENT_SLOTS]['FOOTER_COMPONENT'];
        },
        jurisdictionName() {
            let countryName = this.$t(`countries.${this.country}`, { indefinite: true }) || this.brandPreference.countryName;
            return countryName || '';
        },
    },
    methods: {
        isAuthRequired(menuItem) {
            return helper.processMenuAuth(menuItem.requireAuth, this.isAuthenticated);
        },
        categoryClick(category) {
            this.$router.push({ name: routeName.UPCOMING, params: { categoryId: category.id } });
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, category.id);
            this.$store.dispatch(action.SET_SIDEBAR_STATE, true);
            this.$scroll.scrollTo(0);
        },
        redirectToLandingPage() {
            if (deviceType.isApp()) {
                android.openFlag();
            } else {
                let flagPageDomain = {
                    test: 'com.test.fe.verekuu.com',
                    develop: 'com.develop.fe.verekuu.com',
                    staging: 'com.staging.fe.verekuu.com',
                    production: 'betpawa.com',
                }[ENV];
                window.location.href = `https://${flagPageDomain}/return`;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.main-footer {
    margin-top: auto;
    background: $footer-background;
    border-top: $border;
    padding-bottom: 44px;
    width: 100%;
    text-align: center;
    position: relative;

    &-logo {
        width: 100%;
        max-width: 180px;
        border: 0;
        vertical-align: middle;
        margin: 10px 0;
    }
}

.legal-text {
    text-align: center;
    padding: 0 10px;

    ::v-deep a {
        @extend %link-small-font;
        color: $footer-color;
    }

    p {
        @extend %small-details-text;
        margin: 1em 0;
        color: $footer-color;
    }
}

.footer-social {
    margin-top: 10px;

    a {
        margin: 0 0.4rem 0 0.4rem;

        .icon-footer {
            width: 32px;
            min-width: 32px;
            height: 32px;
            max-height: 100%;
            transform: translateY(-6%);
            vertical-align: middle;
            fill: $footer-icon-fill;
        }
    }
}

.footer-link {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.footer-category {
    display: inline-block;
    margin: 0 7px;
    color: $white-text;
    @extend %link-small-font;
}

.footer-language {
    max-width: 300px;
    margin: auto;
    padding: 12px 10px;
}

.cta-container {
    background: $footer-cta-container-background;
    padding: 10px 0 10px 0;
    text-align: center;
    border-bottom: $border;
}

.jurisdiction-selector {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .country-name {
        font-size: 12px;
        font-weight: 700;
        margin: 0 5px;
        color: $white-text;
    }

    .flag {
        width: 24px;
        height: 16px;
    }

    a {
        font-size: 12px;
        color: $white-text;
    }
}

ul.footer-menu,
ul.footer-user-menu {
    list-style: none;

    li {
        display: inline-block;
        margin: 0 7px;
        @extend %link-small-font;

        a {
            color: $white-bg;
        }
    }
}
</style>
