<template>
    <div v-if="games && games.length > 0">
        <Headline v-if="title" :title="title" :isCategory="true" :icon="iconId" />
        <div class="content">
            <div class="casino-list">
                <div v-for="game in games" class="casino-item" :key="game.id" @click="openCasinoGame(game)">
                    <div class="casino-img-wrapper">
                        <ResponsiveImage
                            class="casino-img card-item-image"
                            v-if="isGameWithImage(game)"
                            :images="imagesByGameId[game.id]"
                            @error="imageError.push(game.id)"
                        />
                        <div v-else class="casino-img-error card-item-image card-item-error">
                            <SvgIcon class="icon-size-very-large pointer" icon-id="icon-casino" />
                        </div>
                    </div>
                    <div class="casino-item-text">{{ getGameName(game) }}</div>
                </div>
            </div>
            <CasinoShowAllGames v-if="showMoreGamesOnBottom" />
        </div>
    </div>
</template>

<script>
import Headline from '@/components/Headline.vue';
import { routeName } from '@/router/const-name';
import CasinoShowAllGames from '@/components/content/CasinoShowAllGames.vue';
import { getImagesForCasinoGame } from '@/modules/casino/utils/CasinoCollection-utils';

const ResponsiveImage = () => import('@/components/ResponsiveImage.vue');

export default {
    name: 'CasinoCollectionHomePage',
    components: { ResponsiveImage, Headline, CasinoShowAllGames },
    props: {
        games: Array,
        title: String,
        iconId: String,
        showMoreGamesOnBottom: Boolean,
    },
    data() {
        return {
            imageError: [],
        };
    },
    computed: {
        imagesByGameId() {
            return this.games.reduce((imagesByGameId, game) => {
                const gameImages = getImagesForCasinoGame(game);
                return {
                    ...imagesByGameId,
                    [game.id]: gameImages,
                };
            }, {});
        },
    },
    methods: {
        getGameName(game) {
            return game.name || '';
        },
        isGameWithImage(game) {
            return !this.imageError.includes(game.id) && this.imagesByGameId[game.id].length > 0;
        },
        openCasinoGame(game) {
            this.$router.push({ name: routeName.CASINO_GAMES_DETAILS, params: { gameSlug: `${game.slug}` } });
        },
    },
};
</script>

<style scoped lang="scss">
.content {
    .casino-list {
        padding: 16px;
        display: flex;
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;

        .casino-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 8px;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: $primary-color;
            }

            .casino-img-wrapper {
                width: 120px;
                height: 120px;
                position: relative;

                &:hover:before {
                    display: block;
                    width: 100%;
                    height: 100%;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(224.07deg, rgba(0, 0, 0, 0.6) 3.22%, rgba(0, 0, 0, 0) 81.24%);
                    z-index: 1;
                    border-radius: 8px;
                }

                img {
                    width: inherit;
                    height: inherit;
                    border-radius: 8px;
                    border: 0.75px $medium-grey;
                    object-fit: cover;
                    object-position: bottom;
                    aspect-ratio: 1;
                }
            }

            .casino-img-error {
                background: $light-grey-bg;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                border-radius: 8px;

                svg {
                    fill: #aaaeb0;
                    height: 36px;
                    width: 36px;
                }
            }

            &-text {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                margin: 4px 0 0 0;
            }
        }
    }
}
</style>
