<template>
    <div class="title-container pointer" @click="openCasinoGames()">
        <h2 v-if="title">
            <SvgIcon v-if="iconId" class="icon-size-small" :icon-id="iconId" />
            <div>{{ title }}</div>
        </h2>
        <div v-if="gamesCount > gamesLimit">
            <span class="pointer more-title">
                <span>{{ $t('ui.casinoLobby.viewAll') }}</span>
                <span class="bold">
                    {{ ` ${gamesCount} ` }}
                    <SvgIcon iconId="arrow_right" class="icon-size-very-small" verticalAlign="baseline" />
                </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CasinoCollectionTitle',
    props: {
        gamesCount: Number,
        gamesLimit: Number,
        title: String,
        iconId: String,
    },
    methods: {
        openCasinoGames() {
            this.$emit('openCasinoGames');
        },
    },
};
</script>

<style scoped lang="scss">
.more-title {
    @extend %body-normal-font-400;
}

.title-container {
    display: flex;
    flex-direction: row;

    h2 {
        flex: 1;
        @extend %body-big-font-700;
        align-items: center;
        display: flex;
        flex-direction: row;

        svg {
            margin-right: 4px;
        }
    }
}
</style>
