const shareDirectLink = `${window.location.origin}?bookingCode={{code}}`;

export const socialShareType = {
    FB: 'fb',
    WU: 'wu',
    TWITTER: 'twitter',
    DIRECT: 'direct',
};

export const socialShareLink = {
    [socialShareType.DIRECT]: shareDirectLink,
    [socialShareType.FB]: `https://wwww.facebook.com/sharer/sharer.php?u=${shareDirectLink}&description={{text}}`,
    [socialShareType.WU]: `whatsapp://send?text={{text}} ${shareDirectLink}`,
    [socialShareType.TWITTER]: `https://twitter.com/intent/tweet?text={{text}} ${shareDirectLink}`,
};
