import axios from 'axios';
import { config as apiConfig, DEVICE_TYPE, helper } from '@agi.packages/core';
import { createErrorHandler } from '@/js/plugins/http/util';

const { VUE_APP_LANGUAGE } = env;
const defaultOptions = {
    defaultLanguage: 'en',
};

// move to core plugins BP-16141
class Http {
    constructor({ language, errorHandler, brandIdentifier }) {
        this._headers = {
            'Content-Type': 'application/json; charset=UTF-8',
            VueJs: true,
            deviceType: DEVICE_TYPE,
            'x-pawa-brand': brandIdentifier,
            'x-pawa-language': language || VUE_APP_LANGUAGE,
            traceId: helper.createUUID(),
        };

        this._axiosInstance = axios.create({
            headers: this._headers,
            timeout: 60000,
            withCredentials: true,
            baseURL: apiConfig.getApiUrl(),
        });

        this._axiosInstance.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return errorHandler(error);
            }
        );
    }

    /**
     * Set headers
     * @param {Object} headers
     */
    setHeaders(headers) {
        this._headers = { ...this._headers, ...headers };
        this._axiosInstance.defaults.headers = { ...this._headers };
    }

    /**
     * Get axios instance
     */
    getInstance() {
        return this._axiosInstance;
    }
}

export default {
    install(Vue, options) {
        const { defaultLanguage, language, dateOptionsGetter, store, langGetter } = { ...defaultOptions, ...options };
        const http = new Http({
            language: language || defaultLanguage,
            errorHandler: createErrorHandler(Vue, store, langGetter, dateOptionsGetter),
        });
        Vue.prototype.$httpBase = Vue.$httpBase = http;
        Vue.prototype.$http = Vue.$http = http.getInstance();
        Vue.prototype.$setHttpHeaders = Vue.$setHttpHeaders = (headers) => http.setHeaders(headers);
    },
};
