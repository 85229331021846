<template>
    <div class="casino-game-brands-tile pointer" @click="openCasinoBrand()">
        <ResponsiveImage v-if="images.length" :images="images" />
    </div>
</template>

<script>
import ResponsiveImage from '@/components/ResponsiveImage.vue';
import { routeName } from '@/router/const-name';
import { getObjectField } from '@/modules/core/utils/helper';

export default {
    name: 'CasinoGameBrandsTile',
    components: { ResponsiveImage },
    props: {
        brand: Object,
    },
    computed: {
        images() {
            if (!getObjectField(this.brand.attributes.logo, 'data')) {
                return [];
            }

            const imagesArr = Object.values(this.brand.attributes.logo.data.attributes.formats ?? {});
            imagesArr.push({
                width: this.brand.attributes.logo.data.attributes.width,
                url: this.brand.attributes.logo.data.attributes.url,
            });
            return imagesArr
                .map((image, index) => ({
                    url: image.url || image,
                    width: image.width,
                }))
                .sort((a, b) => a.width - b.width);
        },
    },
    methods: {
        openCasinoBrand() {
            this.$router.push({
                name: routeName.CASINO_GAMES,
                query: { brand: this.brand.id, ...(this.$route.query.includeDrafts ? { includeDrafts: 'true' } : {}) },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.casino-game-brands-tile {
    margin: 0 6px;
    padding: 12px 18px;
    border-radius: 8px;
    border: 1px solid $medium-grey;

    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    user-select: none;

    img {
        user-select: none;
    }
}
</style>
