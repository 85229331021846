<template>
    <div class="content">
        <CasinoCollectionTitle
            v-if="title"
            @openCasinoGames="openCasinoGames"
            :gamesCount="gamesCount"
            :gamesLimit="gamesLimit"
            :title="title"
            :iconId="iconId"
        />
        <div class="casino-list" v-if="realGames.length > 0 || areCollectionsLoading">
            <CasinoCollectionGameTile v-for="game in games" :game="game" :key="game.id" />
        </div>
        <CasinoFavouritesEmpty v-else-if="isFavourites && realGames.length === 0" />
    </div>
</template>

<script>
import CasinoCollectionGameTile from '@/modules/casino/strapiComponents/components/CasinoCollectionGameTile.vue';
import CasinoCollectionTitle from '@/modules/casino/strapiComponents/components/CasinoCollectionTitle.vue';
import CasinoFavouritesEmpty from '@/modules/casino/components/CasinoFavouritesEmpty.vue';

export default {
    name: 'CasinoCollectionGrid',
    components: { CasinoFavouritesEmpty, CasinoCollectionGameTile, CasinoCollectionTitle },
    props: {
        games: Array,
        gamesCount: Number,
        gamesLimit: Number,
        title: String,
        iconId: String,
        isFavourites: Boolean,
        areCollectionsLoading: Boolean,
    },
    computed: {
        realGames() {
            return this.games.filter((g) => !g.isFake);
        },
    },
    methods: {
        openCasinoGames() {
            this.$emit('openCasinoGames');
        },
    },
};
</script>

<style scoped lang="scss">
.content {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0 12px;

    .casino-list {
        display: grid;
        width: 100%;
        gap: 12px;

        grid-template-columns: repeat(2, minmax(0, 1fr));
        @include mq-xxs {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include mq-xs {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @include mq-sm {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @include mq-md {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}
</style>
