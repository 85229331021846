<template>
    <div v-sniffer="{ handler: onSnifferLoadMore, distance: 150 }" class="casino-lobby no-margins">
        <ErrorPage
            v-if="gameLoadError"
            :title="$t('ui.errorPage.tryAgain')"
            :sectionList="[$t('ui.casinoLobby.connectionIssue')]"
            icon="icon-reload"
            :button="{ text: $t('ui.errorPage.reloadPage'), emit: true }"
            @button:click="errorButtonClick()"
        />
        <ErrorPage
            v-else-if="isPresto"
            icon="icon-alert"
            trackError="incompatible_device_page_reached"
            :title="$t('ui.casinoLobby.gamesCantLoad')"
            :sectionList="[$t('ui.casinoLobby.deviceIsIncompatible')]"
            :button="{ text: $t('ui.casinoLobby.returnToHome'), emit: true }"
            @button:click="returnToHome()"
        />
        <div v-else>
            <CasinoPills />
            <div class="title-container">
                <h2>
                    <SvgIcon v-if="iconId" class="icon-size-small" :icon-id="iconId" />
                    {{ title }}
                </h2>
                <DropDown
                    v-if="gamesCount > 1"
                    class="uppercase"
                    name="casino-lobby"
                    :label="$t('ui.casinoLobby.sort')"
                    :items="sortingOptions"
                    :isOpen="sortingIsOpen"
                    :selected="gamesSortBy"
                    :isLabelUnderline="false"
                    rightSided
                    iconId="icon-sort"
                    @value="setGamesSortBy($event)"
                    @toggle="handleToggleDropdown"
                />
            </div>
            <Spinner v-if="areGamesLoading" :visible="true" class="inset" />
            <CasinoCollectionGrid
                v-else
                :games="games"
                :gamesCount="gamesCount"
                :gamesLimit="gamesLimit"
                :isFavourites="!!filterProps.favourites"
            />
            <Spinner v-if="areMoreGamesLoading" :visible="true" class="inset" />
        </div>
    </div>
</template>

<script>
import { deviceType, getter as coreGetter } from '@/modules/core';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { mapGetters, mapMutations, mapState } from 'vuex';
import CasinoPills from '@/components/content/CasinoPills.vue';
import { action, getter, mutation as generalMutation } from '@/store/store';
import CasinoCollectionGrid from '@/modules/casino/strapiComponents/components/CasinoCollectionGrid.vue';
import { DropDown } from '@/modules/core/components';
import { sortType } from '@/js/casino-const';
import { routeName } from '@/router/const-name';
import { auth } from '@/modules/platform';
import { getObjectField } from '@/modules/core/utils/helper';
import { tryParseNumber } from '@/modules/core/utils/number/tryParseNumber';
import {
    getCasinoCollectionGames,
    getCasinoCollectionGamesCount,
    getCasinoCollectionIsLoading,
    getCasinoCollectionShouldLoad,
    getCasinoGamesLimitForGridPerRow,
} from '@/modules/casino/utils/CasinoCollection-utils';

const GAMES_ROWS_PER_PAGE = 10;
export default {
    name: 'CasinoAllGames',
    components: { DropDown, CasinoCollectionGrid, CasinoPills },
    directives: {
        sniffer: scrollSniffer,
    },
    data() {
        return {
            sortingIsOpen: false,
            isPresto: deviceType.isPresto(),
            currentPage: 0,
        };
    },
    watch: {
        '$route.query.favourites': {
            immediate: true,
            handler() {
                if (!this.filterProps.favourites) {
                    this.$router.replace({ ...this.$route, query: { ...this.$route.query, sort: sortType.POPULAR } });
                    return;
                }
                if (!this.isAuthenticated) {
                    this.goToCasinoLobby();
                    return;
                }

                this.currentPage = 0;
                if (this.$route.query.sort !== sortType.FAVOURITES_ADDED_DESC || this.$route.query.sort !== sortType.FAVOURITES_ADDED_ASC) {
                    this.$router.replace({
                        ...this.$route,
                        query: { ...this.$route.query, sort: sortType.FAVOURITES_ADDED_DESC },
                    });
                }
            },
        },
        '$route.query.sort': {
            immediate: true,
            handler() {
                this.currentPage = 0;
            },
        },
        '$route.query.category': {
            immediate: true,
            handler() {
                this.currentPage = 0;
                if (this.categoryId) {
                    this.$store.dispatch(action.GET_CASINO_CATEGORY, {
                        categoryId: this.categoryId,
                        includeDrafts: this.$route.query.includeDrafts,
                    });
                }
            },
        },
        '$route.query.brand': {
            immediate: true,
            handler() {
                this.currentPage = 0;
                if (this.brandId) {
                    this.$store.dispatch(action.GET_CASINO_GAME_BRAND, {
                        brandId: this.brandId,
                        includeDrafts: this.$route.query.includeDrafts,
                    });
                }
            },
        },
        loadProps: {
            immediate: true,
            handler() {
                if (this.isPresto) {
                    return;
                }

                if (!this.shouldLoadGames) {
                    return;
                }

                this.$store.dispatch(action.GET_CASINO_GAMES_STRAPI, this.loadProps);
            },
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            categoriesById: getter.GET_GAME_CATEGORY,
            brandsById: getter.GET_GAME_BRAND,
            isLoading: coreGetter.IS_LOADING,
            favoriteGamesIds: getter.GET_FAVORITE_GAMES_IDS,
        }),
        ...mapState({
            gameLaunchError: (state) => state.gameLaunchError,
            gameLoadError: (state) => state.casino.error,
            gamesCollection: (state) => state.games,
        }),
        sortingOptions() {
            if (this.shouldShowFavouritesOnly) {
                return [
                    { key: this.$t(`ui.casinoLobby.favourites_added_desc`), value: sortType.FAVOURITES_ADDED_DESC },
                    { key: this.$t(`ui.casinoLobby.favourites_added_asc`), value: sortType.FAVOURITES_ADDED_ASC },
                ];
            }
            return [
                { key: this.$t(`ui.casinoLobby.pop`), value: sortType.POPULAR },
                { key: this.$t(`ui.casinoLobby.ascending`), value: sortType.NAME_ASC },
                { key: this.$t(`ui.casinoLobby.descending`), value: sortType.NAME_DESC },
            ];
        },
        areGamesLoading() {
            return this.isLoading(action.GET_CASINO_GAMES_STRAPI) && this.games.length === 0;
        },
        areMoreGamesLoading() {
            return this.isLoading(action.GET_CASINO_GAMES_STRAPI) && this.games.length > 0;
        },
        categoryId() {
            return tryParseNumber(this.$route.query.category);
        },
        gamesSortBy() {
            return this.$route.query.sort;
        },
        categoryResults() {
            if (!this.categoriesById || !this.categoryId) {
                return null;
            }
            return this.categoriesById[this.categoryId];
        },
        category() {
            return getObjectField(this.categoryResults, 'data');
        },
        brandId() {
            return tryParseNumber(this.$route.query.brand);
        },
        brandResults() {
            if (!this.brandsById || !this.brandId) {
                return null;
            }
            return this.brandsById[this.brandId];
        },
        brand() {
            return getObjectField(this.brandResults, 'data');
        },
        title() {
            if (this.category) {
                return this.category.name;
            }
            if (this.brand) {
                return this.brand.name;
            }
            if (this.filterProps.favourites) {
                return this.$t(`ui.casinoLobby.favourites`);
            }
            return this.$t('ui.eventPage.category.all');
        },
        iconId() {
            if (this.category) {
                return this.category.iconId;
            }
            return null;
        },
        games() {
            const games = [];
            for (let p = 0; p <= this.currentPage; p += 1) {
                const gamesForPage = getCasinoCollectionGames(
                    {
                        filterProps: this.filterProps,
                        limit: this.gamesLimit,
                        start: p * this.gamesLimit,
                        sortBy: this.gamesSortBy,
                    },
                    this.gamesCollection
                );
                games.push(...gamesForPage);
            }
            return games;
        },
        gamesCount() {
            return getCasinoCollectionGamesCount(
                {
                    filterProps: this.filterProps,
                    limit: this.gamesLimit,
                    sortBy: this.gamesSortBy,
                    start: 0,
                },
                this.gamesCollection
            );
        },
        filterProps() {
            if (this.categoryId) {
                return { categoryId: this.categoryId };
            }
            if (this.$route.query.brand) {
                return { brandId: this.$route.query.brand };
            }
            if (this.shouldShowFavouritesOnly) {
                return { favourites: this.favoriteGamesIds };
            }
            return {};
        },
        shouldShowFavouritesOnly() {
            return !!this.$route.query.favourites;
        },
        shouldLoadGames() {
            return getCasinoCollectionShouldLoad(
                {
                    filterProps: this.filterProps,
                    limit: this.gamesLimit,
                    start: this.currentPage * this.gamesLimit,
                    sortBy: this.gamesSortBy,
                },
                this.gamesCollection
            );
        },
        shouldLoadNextPage() {
            const gamesCount = this.shouldShowFavouritesOnly
                ? this.favoriteGamesIds.old.length + this.favoriteGamesIds.strapi.length
                : this.gamesCount;

            if (gamesCount <= (this.currentPage + 1) * this.gamesLimit) {
                return false;
            }
            if (this.areGamesLoading) {
                return false;
            }
            const isPageLoading = getCasinoCollectionIsLoading(
                {
                    filterProps: this.filterProps,
                    limit: this.gamesLimit,
                    start: this.currentPage * this.gamesLimit,
                    sortBy: this.gamesSortBy,
                },
                this.gamesCollection
            );
            return !isPageLoading;
        },
        gamesLimit() {
            const oneRowLimit = getCasinoGamesLimitForGridPerRow(this.$mq.size);
            return oneRowLimit * GAMES_ROWS_PER_PAGE;
        },
        loadProps() {
            return {
                filterProps: this.filterProps,
                start: this.currentPage * this.gamesLimit,
                limit: this.gamesLimit,
                sortBy: this.gamesSortBy,
                includeDrafts: this.$route.query.includeDrafts,
            };
        },
    },
    methods: {
        ...mapMutations({
            clearNotification: generalMutation.CLEAR_NOTIFICATIONS,
        }),
        handleToggleDropdown() {
            this.sortingIsOpen = !this.sortingIsOpen;
        },
        returnToHome() {
            this.$router.push({
                name: routeName.HOMEPAGE,
            });
        },
        goToCasinoLobby() {
            this.$router.push({
                name: routeName.CASINO,
                query: this.$route.query.includeDrafts ? { includeDrafts: 'true' } : {},
            });
        },
        setGamesSortBy(sortBy) {
            this.$router.push({ name: routeName.CASINO_GAMES, query: { ...this.$route.query, sort: sortBy } });
        },
        errorButtonClick() {
            window.location.reload();
        },
        onSnifferLoadMore() {
            if (!this.shouldLoadNextPage) {
                return;
            }

            this.loadMoreCasinoItems();
            this.$gtm.query({
                event: 'casino_lobby_game_list_auto_scrolling_triggered',
                gamesLoadedCount: this.games.length,
            });
        },
        loadMoreCasinoItems() {
            this.currentPage += 1;
        },
    },
    created() {
        this.$gtm.query({ event: 'casino_lobby_page_view' });
    },
    beforeDestroy() {
        this.clearNotification();
    },
};
</script>

<style scoped lang="scss">
.title-container {
    display: flex;
    flex-direction: row;
    margin: 0 12px;

    h2 {
        flex: 1;
        margin: 0;
        padding: 0;
    }
}
</style>
