<template>
    <span v-if="isLocked" class="event-bet-wrapper" :class="{ 'event-bet-wrapper-hidden': price === 'placeholder' }">
        <span class="event-bet">
            <span class="anchor-wrap">
                <span class="event-selection event-selection_locked">{{ price }}</span>
                <span class="event-odds event-odds_locked">
                    <SvgIcon class="icon-size-medium" iconId="icon-lock"></SvgIcon>
                </span>
            </span>
        </span>
    </span>
    <span v-else-if="price" class="event-bet-wrapper">
        <span :class="['event-bet', { selected: selected }]">
            <span v-if="!price.price" class="anchor-wrap">
                <span class="event-selection event-selection_locked">{{ price.name }}</span>
                <span class="event-odds event-odds_locked">
                    <SvgIcon class="icon-size-medium" iconId="icon-lock"></SvgIcon>
                </span>
            </span>
            <span
                v-else
                @click="$emit('onPriceClick', price)"
                class="anchor-wrap"
                :class="[{ 'event-page': isEventRoute }, { multiline: isMultiline }]"
                :id="'Bp-Price-' + marketTypeId + '-' + price.id"
            >
                <span class="event-selection">
                    {{ price.handicap ? price.name + ' (' + price.handicap + ')' : price.name }}
                </span>
                <span class="event-odds">
                    <SvgIcon v-if="price.suspended" class="price-icon" iconId="icon-lock" />
                    <template v-else>
                        <SvgIcon v-if="isHot" iconId="icon-hot" class="price-icon icon-hot" />
                        <span>{{ $numberFormat(Number(price.price)) }}</span>
                    </template>
                </span>
            </span>
        </span>
    </span>
    <span v-else class="event-bet-wrapper event-bet-wrapper-hidden"></span>
</template>

<script>
import { deviceType, helper } from '@agi.packages/core';

export default {
    name: 'BetPrice',
    props: {
        price: {
            type: [String, Object], // string for case: isLocked === true
            required: false,
        },
        isLocked: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        isEventRoute: {
            type: Boolean,
            default: false,
        },
        isMultiline: {
            type: Boolean,
            default: false,
        },
        marketTypeId: {
            type: String,
            default: '',
        },
        isHot: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    methods: {
        $numberFormat: helper.numberFormat,
    },
};
</script>

<style lang="scss" scoped>
.event-bet {
    border: $betline-event-border;
    cursor: pointer;
    background: $betline-event-odds-background;
    color: $betline-event-odds-color;
    width: 100%;
    display: flex;
    @include no_flex_box_supported {
        text-align: center;
        flex: none;
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
    }

    &.selected {
        color: $betline-event-odds-color-selected;
        background-color: $betline-event-odds-background-selected;
        border: $betline-event-border-selected;
    }

    &-wrapper {
        flex: 1;
        padding: 0 4px 5px;
        @include all_but_mini {
            display: flex;
        }

        @include no_flex_box_supported {
            flex: none;
            display: inline-block;
            padding: 0 2px 5px;
        }

        @include oldschool {
            padding: 0 2px 5px;
        }

        &-hidden {
            visibility: hidden;
        }
    }

    .anchor-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @include only_ie {
            display: block;
        }

        @include maxbasic {
            .event-odds {
                flex-grow: 0;
            }
        }

        .price-icon {
            height: 14px;
            width: 14px;
            vertical-align: top;
            margin-top: -4px;
        }

        .icon-hot {
            margin-right: 4px;
            fill: $betline-icon-hot-fill;
        }

        &.multiline {
            flex-direction: column;
            justify-content: space-between;
            text-align: left;

            .event-selection {
                padding: 5px 10px 3px 10px;

                @include mainnotbasic {
                    padding: 5px 7px 3px 7px;
                }
                @include maxbasic {
                    padding: 5px 4px 3px 4px;
                    line-height: 18px;
                }
                @include no_flex_box_supported {
                    @include oldschool {
                        text-align: center;
                    }
                }
            }
            .event-odds {
                padding: 3px 10px 5px 10px;
                flex-grow: 0;
                padding-top: 0;

                @include mainnotbasic {
                    padding: 3px 7px 5px 7px;
                }
                @include maxbasic {
                    padding: 3px 4px 5px 4px;
                    line-height: 18px;
                }
                @include no_flex_box_supported {
                    @include oldschool {
                        text-align: center;
                    }
                }
            }
        }
    }

    .event-selection,
    .event-odds {
        padding: 10px 10px;
        @include only_ie {
            display: block;
        }
        @include mainnotbasic {
            padding: 10px 7px;
        }
    }

    .event-selection {
        @extend %body-normal-font-400;
        border-radius: $border-radius-left;
        word-break: break-word;
        flex-basis: 55%;
        text-align: left;

        @include maxbasic {
            flex: 0;
            border-radius: $border-radius-top;
            padding: 5px 4px;
            height: 26px;
        }

        @include oldschool {
            padding: 5px 2px;
            word-break: break-all;
        }

        @include only_mini {
            @include skip_element;
        }
    }

    .event-odds {
        @extend %body-normal-font-700;
        border-radius: $border-radius-right;
        white-space: nowrap;
        text-align: left;

        &_locked {
            height: 26px;
            padding: 8px 10px 8px 10px;
        }

        @include maxbasic {
            border-radius: $border-radius-bottom;
            padding: 5px 4px 7px;
            line-height: 15px;
            height: 26px;
        }
        @include oldschool {
            padding: 5px 2px;
            word-break: break-all;
        }

        @include only_mini {
            @include skip_element;
        }
    }
}
</style>
