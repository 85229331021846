<template>
    <div>
        <div v-if="!marketUnavailable" class="event-bets">
            <div class="chunks">
                <div v-for="(chunk, chunkIndex) in pricesChunks" :key="`chunk-${eventId}-${chunkIndex}`" class="chunk">
                    <template v-if="!isPresto">
                        <BetPrice
                            v-for="(price, index) in chunk"
                            :key="`${eventId}-${index}`"
                            :price="price"
                            :isLocked="false"
                            :selected="price && isInBetslip(Number(price.id))"
                            :isEventRoute="isEventRoute"
                            :isMultiline="isMultiline"
                            :marketTypeId="String(market.marketType.id)"
                            :isHot="price && isHot(price.additionalInfo.hot)"
                            @onPriceClick="toggleBet(price, market)"
                            class="bet-price"
                        />
                    </template>
                    <div v-else class="prices-wrapper">
                        <BetPrice
                            v-for="(price, index) in chunk"
                            :key="`${eventId}-${index}`"
                            :price="price"
                            :isLocked="false"
                            :selected="price && isInBetslip(Number(price.id))"
                            :isEventRoute="isEventRoute"
                            :isMultiline="isMultiline"
                            :marketTypeId="String(market.marketType.id)"
                            :isHot="price && isHot(price.additionalInfo.hot)"
                            @onPriceClick="toggleBet(price, market)"
                            class="bet-price"
                            :class="[pricesChunks.length > 0 && `bet-price-size-${pricesChunkSize}`]"
                        />
                    </div>
                    <slot :chunkIndex="chunkIndex"></slot>
                </div>
            </div>
        </div>
        <div class="event-bets" v-else>
            <div class="chunks">
                <div class="chunk">
                    <template v-if="!isPresto">
                        <BetPrice
                            v-for="(item, index) in lockedList"
                            :key="`locked-item-${index}`"
                            :price="item"
                            :isLocked="true"
                            class="bet-price"
                        />
                    </template>
                    <div v-else class="prices-wrapper">
                        <BetPrice
                            v-for="(item, index) in lockedList"
                            :key="`locked-item-${index}`"
                            :price="item"
                            :isLocked="true"
                            class="bet-price"
                        />
                    </div>
                    <slot :chunkIndex="0"></slot>
                </div>
            </div>
        </div>
        <OpenBetslip
            class="event-bets-line"
            v-if="isPresto && availableOpenBetslip && showOpenBetslip"
            iconId="arrow_left"
            :pointer="true"
        />
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { deviceType, helper } from '@agi.packages/core';
import { sport, betslip, betslipType, CATEGORY_MAP } from '@agi.packages/sport';
import { getter as platformGetter } from '@agi.packages/platform';

import OpenBetslip from './OpenBetslip.vue';
import BetPrice from './BetPrice.vue';
import { routeName } from '@/router/const-name';

export default {
    name: 'Betline',
    props: {
        rawDate: String,
        market: Object,
        fullname: String,
        eventId: Number,
        sportId: Number,
        isLive: Boolean,
        exId: Number,
        type: {
            type: String,
            default: betslipType.REGULAR,
            validator: (type) => {
                return [betslipType.REGULAR, betslipType.VIRTUAL].indexOf(type) !== -1;
            },
        },
        season: {
            type: Object,
            default: () => ({}),
        },
        availableOpenBetslip: {
            type: Boolean,
            default: false,
        },
        marketUnavailable: {
            type: Boolean,
            default: false,
        },
        isFirstTab: {
            type: [Boolean, undefined],
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            ORDER_PRICES_BY_TYPE_ID: 'typeId',
        };
    },
    components: { OpenBetslip, BetPrice },
    computed: {
        ...mapState({
            betslipStatus: (state) => state.sport.betslip.regular.status, // getter BP-16141
            virtualBetslipStatus: (state) => state.sport.betslip.virtual.status, // getter BP-16141
            selectedPriceId: (state) => state.sport.betslip.selectedPriceId,
            containsHot: (state) => state.sport.containsHot,
            currentSeason: (state) => state.sport.betslip.virtual.season, // getter BP-16141
        }),
        ...mapGetters({
            betslip: betslip.getter.GET_SELECTED_BETSLIP,
            selectionIds: betslip.getter.GET_SELECTION_IDS_BY_TYPE,
            minimumOddsForBonus: platformGetter.GET_MINIMUM_ODDS_FOR_BONUS,
        }),
        showOpenBetslip() {
            return this.market?.prices.find((price) => Number(price.id) === this.selectedPriceId);
        },
        lockedList() {
            const { markets } = CATEGORY_MAP.find(({ key }) => key === this.sportId) || {};
            const needMap = typeof this.isFirstTab === 'undefined' || this.isFirstTab;
            return (needMap && markets) || ['placeholder'];
        },
        isEventRoute() {
            return this.$route.name === routeName.EVENT;
        },
        isVirtual() {
            return this.type === betslipType.VIRTUAL;
        },
        prices() {
            const unOrderedPrices = [...(this.market.prices || [])];
            return unOrderedPrices.sort((a, b) => a[this.ORDER_PRICES_BY_TYPE_ID] - b[this.ORDER_PRICES_BY_TYPE_ID]);
        },
        pricesChunkSize() {
            return this.market.marketType.priceColumnCount;
        },
        pricesChunks() {
            const ch = helper.chunk(this.prices, this.pricesChunkSize, true);
            return ch;
        },
        isMultiline() {
            const columnCount = this.pricesChunkSize;
            const BRACKET_LENGTH = 3;

            const longestPriceName = this.market.prices.reduce((maxLength, price) => {
                const priceNameLength = price.name.length + (price.handicap ? price.handicap.length + BRACKET_LENGTH : 0);
                return Math.max(maxLength, priceNameLength);
            }, 0);

            const conditions = [
                { sizes: ['lg'], columnCount: 3, maxLength: 16 },
                { sizes: ['sm', 'md'], columnCount: 3, maxLength: 9 },
                { sizes: ['xs'], columnCount: 3, maxLength: 4 },
                { sizes: ['xs'], columnCount: 2, maxLength: 9 },
                { sizes: ['xxs'], columnCount: 3 },
                { sizes: ['xxs'], columnCount: 2, maxLength: 9 },
                { sizes: ['vxs', 'ivxs', 'xxxs'] },
            ];

            for (let condition of conditions) {
                const sizeMatches = condition.sizes.includes(this.$mq.size);
                const maxLengthCondition = condition.maxLength === undefined || longestPriceName > condition.maxLength;
                const columnCountCondition = condition.columnCount === undefined || columnCount === condition.columnCount;

                if (sizeMatches && maxLengthCondition && columnCountCondition) {
                    return true;
                }
            }
            return false;
        },
    },
    methods: {
        ...mapMutations({
            setSeason: betslip.mutation.SET_VIRTUAL_BETSLIP_SEASON,
        }),
        isInBetslip(targetId) {
            return this.selectionIds(this.type).includes(targetId) && !this.betslip.status.Placed;
        },
        toggleBet: function (price, market) {
            if (price.suspended) return;
            const [splitMarketTypeName] = this.market.marketType.name.split(' - ');
            this.$emit('select:price', price);
            if (this.isRemoveAllBet()) {
                this.$store.dispatch(betslip.action.REMOVE_ALL_BETS, this.type); // remove param BP-16141
            }
            if (this.isVirtual && !this.currentSeason.id) {
                this.setSeason(this.season);
            }
            this.$store.dispatch(betslip.action.TOGGLE_BET, {
                marketPrices: market.prices.map(({ id }) => id),
                rawDate: this.rawDate,
                market: {
                    name: this.market.marketType.name,
                    displayName: this.market.marketType.displayName,
                    groupName: splitMarketTypeName,
                    groupedMarketName: this.market.marketType.groupedName,
                    handicapType: this.market.handicapType,
                    cashoutable: this.market.additionalInfo.cashoutable,
                    twoUp: this.market.additionalInfo.twoUp,
                },
                price: {
                    Id: Number(price.id),
                    Name: price.name,
                    Price: this.$numberFormat(price.price),
                    PriceRaw: price.price,
                    EligibleForBonus: price.price >= this.minimumOddsForBonus,
                    Hcp: price.handicap,
                },
                name: this.fullname,
                eventId: this.eventId,
                exId: this.exId,
                isLive: this.isLive,
                type: this.type,
            });
            this.$gtm.query({ event: 'bet_item_selected', fullName: this.fullname });
        },
        isRemoveAllBet() {
            return (this.isVirtual && this.virtualBetslipStatus.Placed) || (!this.isVirtual && this.betslipStatus.Placed);
        },
        isHot: function (value) {
            if (!this.containsHot && value) {
                this.$store.commit(sport.mutation.CONTAINS_HOT, value);
            }
            return value;
        },
        $numberFormat: helper.numberFormat,
    },
    beforeDestroy: function () {
        if (this.containsHot) {
            this.$store.commit(sport.mutation.CONTAINS_HOT, false);
        }
    },
};
</script>

<style lang="scss" scoped>
.event-bets {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex: 1;
    line-height: 16px;
    text-align: center;
    margin: 0 -4px;
    flex-wrap: nowrap;

    @include only_ie {
        display: block;
    }
    @include oldoldschool {
        flex-wrap: wrap;
    }

    // Opera mini and IE have a problem with flex
    @include no_flex_box_supported {
        @include maxwp {
            .event-selection,
            .event-odds {
                padding-left: 0;
                padding-right: 0;
            }
        }

        flex: none;
        text-align: left;
        display: block;
        margin: 0 -2px;
    }

    @include oldschool {
        margin: 0 -2px;
    }
}

.event-bets-line {
    margin: 5px 0 -12px;
}

.chunks {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    @include no_flex_box_supported {
        display: table;
        width: 100%;
    }
}

.chunk {
    display: flex;
    flex-grow: 1;
    width: 100%;
    @include no_flex_box_supported {
        display: table-row;
    }
}
.prices-wrapper {
    @include no_flex_box_supported {
        display: table-cell;
        width: 100%;
    }
}
.bet-price {
    &-size-3 {
        width: 33.3%;
    }
    &-size-2 {
        width: 50%;
    }
    &-size-1 {
        width: 100%;
    }

    @include no_flex_box_supported {
        width: 33.3%;
        @include oldschool {
            width: 50%;

            &:last-child {
                width: 100%;
                float: none;
            }
        }
        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ span {
            width: 50%;
        }
    }
}
</style>
