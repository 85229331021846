<template>
    <div v-if="event" class="event">
        <LiveEvent :event="event" :marketId="marketId" v-if="isLive && event.id" />
        <EventHeader v-if="!isLive && event.id" :event="event" :market-id="marketId" />
        <div class="widget-wrapper">
            <slot name="widget"></slot>
        </div>
        <div :class="{ 'tabs-container': true, 'presto-tabs': isPresto }">
            <Tabs
                v-if="isAllMarketsEnabled"
                :class="{
                    'event-tabs-event-ended': !hasMarkets || isEventEnded,
                    'event-tabs-with-markets': hasMarkets,
                    'event-tabs-top-border': isStatisticOpen,
                }"
                class="event-tabs"
                :tabs="marketTabs"
                scrollable
                bottomBorder
                tabType="text"
                :active="currentTab"
                align="left"
                usePrestoTabs
                tabChangeTrackingName="event_tabs_click_"
                @select="setCurrentTab"
            />
        </div>
        <div v-if="isEventEnded" class="event-ended">
            <h4>{{ $t('ui.eventPage.eventEnded.title') }}</h4>
            <p>{{ $t('ui.eventPage.eventEnded.body') }}</p>
            <router-link :to="{ path: '/upcoming' }" :class="{ 'button-full': $mq.isVerySmall }" class="button button-primary">
                {{ $t('ui.eventPage.eventEnded.browseEvents') }}
            </router-link>
            <div class="event-ended-links">
                <router-link :to="{ path: '/' }" class="underline event-ended-link">
                    {{ $t('ui.eventPage.eventEnded.home') }}
                </router-link>
                <router-link :to="{ path: '/help' }" class="underline event-ended-link">
                    {{ $t('ui.eventPage.eventEnded.help') }}
                </router-link>
            </div>
        </div>
        <ErrorPage
            v-else-if="!hasMarketsByFilter && showMarkets"
            :title="errorMessage.title"
            :sectionList="errorMessage.description ? [errorMessage.description] : []"
        >
            <template slot="header">
                <img v-if="imgSrc" class="error-image" :src="imgSrc" />
            </template>
            <template slot="footer">
                <button v-if="errors.length || !isLive" class="button button-primary error-button" @click="reload()">
                    {{ $t('ui.common.refresh') }}
                </button>
                <router-link v-if="!hasMarkets" class="underline error-link bold" to="/upcoming">
                    {{ $t(`ui.eventPage.eventEnded.browseUpcomingEvents`) }}
                </router-link>
                <router-link v-else class="underline error-link" :to="{ path: $route.path, query: { filter: 'all' } }">
                    {{ $t(`ui.common.viewAll`) }}
                </router-link>
            </template>
        </ErrorPage>
        <template v-else>
            <div v-for="(marketGroup, index) in markets" :key="'market-group-' + index" class="events-container">
                <Modal
                    :name="`market-group-info-${marketGroup[0].marketType.id}`"
                    width="80%"
                    :options="{ position: { top: '26px' } }"
                    :avoidCollisionWithFooter="true"
                    @close="$modal.hide(`market-group-info-${marketGroup[0].marketType.id}`)"
                >
                    {{ marketGroup[0].marketType.explainer }}
                </Modal>
                <div class="events-sub-container">
                    <div class="events-sub-header">
                        <div class="event-title">
                            <h4>{{ setMarketTitle(marketGroup[0].marketType) }}</h4>
                            <span
                                v-if="!!marketGroup[0].marketType.explainer"
                                @click="openModal(event, marketGroup)"
                                class="event-info"
                                :data-test-id="'marketInfo-' + marketGroup[0].marketType.id"
                            >
                                <SvgIcon class="event-info-icon" icon-id="icon-info" />
                            </span>
                        </div>
                        <div class="icons-right">
                            <Badge
                                v-if="isTwoUpIconVisible(marketGroup[0])"
                                class="best-odds-holder"
                                type="best-odds"
                                iconName="icon-two-up"
                            />
                            <Badge
                                v-if="isBoostedIconVisible(marketGroup[0])"
                                class="best-odds-holder"
                                type="best-odds"
                                iconName="icon-best-odds"
                                :text="bestOddsText"
                                :iconTooltip="$t('project.sports.boosted.text')"
                            />
                        </div>
                    </div>
                    <BetlineList
                        :event="event"
                        :markets="showMarkets ? marketGroup : []"
                        :showMarketCount="!showMarkets"
                        :availableOpenBetslip="true"
                    />
                </div>
            </div>
        </template>
        <HotDescription v-if="containsHot && isAllMarketsEnabled" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
    marketTypeCategory,
    CATEGORY_MAP,
    filterCategory,
    sportFilters,
    sport,
    EXCLUDED_MARKET_NAMES,
    NO_SORT_ON_ALL_TAB_SPORTS,
    marketType,
    WS_FILTERS_TEMPLATE,
} from '@agi.packages/sport';
import { deviceType, helper } from '@agi.packages/core';
import { auth, getter as generalGetter } from '@agi.packages/platform';
import { Tabs } from '@agi.packages/core/components';
import Badge from '@/components/Badge.vue';
import HotDescription from '@/components/HotDescription.vue';
import BetlineList from './BetlineList.vue';
import LiveEvent from './LiveEvent.vue';
import EventHeader from './EventHeader.vue';
import ErrorPage from '@/components/Pages/ErrorPage.vue';
import { ERROR_IMAGES } from '@/components/content/content-const';
import { routeName } from '@/router/const-name';

export default {
    name: 'Event',
    components: { LiveEvent, BetlineList, HotDescription, Tabs, EventHeader, ErrorPage, Badge },
    props: {
        event: {
            default: null,
        },
        marketId: [String, Number],
    },
    data() {
        return {
            currentFilter: filterCategory.ALL,
            socketCancel: null,
            isPresto: deviceType.isPresto(),
            isSocketChannelRequested: false,
        };
    },
    computed: {
        ...mapGetters({
            dateOptions: generalGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            settings: generalGetter.GET_SETTINGS,
            bestOdds: generalGetter.GET_BEST_ODDS,
            isBoostedIconEnabled: generalGetter.IS_BOOSTED_ICON_ENABLED,
            isLiveEventBestOddsIconEnabled: generalGetter.IS_LIVE_EVENT_BEST_ODDS_ICON_ENABLED,
            isEventPageSocketsEnabled: generalGetter.IS_EVENT_PAGE_SOCKETS_ENABLED,
            marketTabsConfiguration: generalGetter.GET_SPORTSBOOK_TABS_CONFIGURATION,
        }),
        ...mapState({
            isStatisticOpen: (state) => state.sport.isStatisticOpen,
            errors: (state) => state.sport.sports.singleEvent.errors,
            containsHot: (state) => state.sport.sports.containsHot,
        }),
        sportType() {
            return Number(this.event?.category.id);
        },
        showMarkets() {
            return this.marketId !== marketTypeCategory.NONE;
        },
        isAllMarketsEnabled() {
            return this.marketId === marketTypeCategory.ALL;
        },
        currentCategory() {
            const { name } = CATEGORY_MAP.find(({ key }) => this.sportType === key) || {};
            return sportFilters[name] || {};
        },
        marketTabs() {
            return this.marketTabsConfiguration
                .reduce((acc, tab) => {
                    const tabSpecs = tab.categorySpecs.find(({ id }) => id === String(this.sportType));

                    if (!tabSpecs) {
                        return acc;
                    }

                    const tabHasMarkets = this.allEventMarketsIds.includes(tab.id);
                    const formattedTab = {
                        key: tab.id,
                        text: this.$t(`ui.eventPage.category.${tab.id}`, { indefinite: true }) || tab.name,
                        priority: tabSpecs.priority,
                    };

                    return tabSpecs.allowEmpty || tabHasMarkets ? [...acc, formattedTab] : acc;
                }, [])
                .sort((a, b) => a.priority - b.priority);
        },
        currentTab() {
            return {
                key: this.$route.query.filter || filterCategory.ALL,
            };
        },
        markets() {
            const { markets } = this.event;
            const { name } = CATEGORY_MAP.find(({ key }) => this.sportType === key);
            const orderBy = sportFilters[name][this.currentFilter];
            if ((NO_SORT_ON_ALL_TAB_SPORTS.includes(name) && this.currentFilter === filterCategory.ALL) || !orderBy) {
                return markets.flat().sort(([marketA], [marketB]) => marketA.marketType.priority - marketB.marketType.priority);
            } else {
                return markets
                    .flat()
                    .sort(([marketA], [marketB]) => {
                        if (marketA.marketType.priority !== marketB.marketType.priority) {
                            return marketA.marketType.priority - marketB.marketType.priority;
                        } else if (orderBy.indexOf(marketA.marketType.id) === -1 || orderBy.indexOf(marketB.marketType.id) === -1) {
                            return orderBy.indexOf(marketB.marketType.id) - orderBy.indexOf(marketA.marketType.id);
                        }
                        return orderBy.indexOf(marketA.marketType.id) - orderBy.indexOf(marketB.marketType.id);
                    })
                    .filter(([market]) => {
                        return !(this.currentFilter !== filterCategory.ALL) || orderBy.includes(market.marketType.id);
                    });
            }
        },
        errorMessage() {
            const allMarkets = this.$t('ui.eventPage.error.description.allMarketsNotAvailable');
            const filteredMarkets = this.isLive
                ? this.$t('ui.eventPage.error.description.noLiveActiveMarkets')
                : this.$t('ui.eventPage.error.description.noActiveMarkets');
            return {
                title: this.$t('ui.eventPage.error.title.noActiveMarkets'),
                description: this.hasMarkets ? filteredMarkets : allMarkets,
            };
        },
        imgSrc() {
            return ERROR_IMAGES.errorNoActiveMarkets;
        },
        hasMarketsByFilter() {
            return !!this.markets?.length;
        },
        hasMarkets() {
            const { markets } = this.event || {};
            return !!markets?.length;
        },
        isLive() {
            return this.event?.additionalInfo?.live;
        },
        isEventEnded() {
            const starts = new Date(this.event?.startTime).getTime();
            const now = new Date().getTime();
            return this.event?.startTime && now > starts && !this.isLive;
        },
        brandIdentifier() {
            return this.settings?.brand?.identifier;
        },
        socketFilter() {
            return this.isLive && this.brandIdentifier && this.event?.id
                ? [WS_FILTERS_TEMPLATE.odds, WS_FILTERS_TEMPLATE.scoreboard].map((template) =>
                      this.$interpolate(template, { brandIdentifier: this.brandIdentifier, eventId: this.event.id })
                  )
                : [];
        },
        bestOddsText() {
            const { bestOddsLabel } = this.bestOdds;
            return !this.$mq.isVerySmall && !this.isPresto ? bestOddsLabel : '';
        },
        allEventMarketsIds() {
            if (!this.event) {
                return [];
            }

            const allMarkets = this.event.markets.flatMap((market) => market[0].flatMap(({ marketType }) => marketType.tabs));

            return helper.removeArrayDuplicates(allMarkets);
        },
    },
    methods: {
        $isEmpty: helper.isEmpty,
        $interpolate: helper.interpolate,
        isBestOdds(marketTypeId) {
            const { leagues, events } = this.bestOdds;
            const league = leagues.find((it) => it.leagueId === this.event.competition.id);
            const event = events.find((it) => it.eventId === this.event.id);
            const markets = (event && event.markets) || (league && league.markets) || [];
            const isMarketBestOdds = markets.length
                ? markets.some((it) => Number(it.marketId) === marketTypeId)
                : marketTypeId === marketType._1X2_FT;
            return (event || league) && isMarketBestOdds;
        },
        isTwoUpIconVisible({ additionalInfo }) {
            return helper.getObjectField(additionalInfo, 'twoUp', false);
        },
        isBoostedIconVisible({ additionalInfo, marketType }) {
            if (this.isBoostedIconEnabled) return additionalInfo.boosted;
            const isEventHasBestOdds = this.isBestOdds(marketType.id);
            if (!isEventHasBestOdds) return false;
            if (this.isLive) return this.isLiveEventBestOddsIconEnabled;
            return true;
        },
        openModal(event, marketGroup) {
            if (!event.id || !marketGroup.length) {
                return;
            }
            this.$gtm.query({
                event: 'info_icon_click',
                sport: event.sport,
                market: event.marketName,
                product: `sports-book-${this.isLive ? 'live' : 'upcoming'}`,
                isAuthenticated: this.isAuthenticated,
            });
            this.$modal.show(`market-group-info-${marketGroup[0].marketType.id}`);
        },
        setCurrentTab({ key }) {
            const currentQueryParams = { ...this.$route.query };
            const { filter } = currentQueryParams;
            if (filter !== key) {
                const routeConfig = {
                    query: {
                        ...this.$route.query,
                        filter: key,
                    },
                };

                if (this.$route.name === routeName.GENERIC_PAGE) {
                    routeConfig.path = this.$route.path;
                } else {
                    routeConfig.name = this.$route.name;
                }

                this.$router.replace(routeConfig);
            }
        },
        errorButtonClick() {
            this.setCurrentTab({ key: filterCategory.ALL });
        },
        formulateMarketTitle(groupName, groupedName) {
            if (groupName === groupedName) return groupName;

            const modifiedGroupName = EXCLUDED_MARKET_NAMES.includes(groupName) ? '' : groupName;
            const modifiedGroupedName = groupedName.replace(' - ', ' | ');
            const separator = modifiedGroupName ? ' | ' : '';

            return `${modifiedGroupName}${separator}${modifiedGroupedName}`;
        },
        reload() {
            window.location.reload();
        },
        setMarketTitle(marketType) {
            if (!marketType.displayName) return marketType.name;
            const { participants } = this.event;
            const home = participants[0]?.name;
            const away = participants[1]?.name;
            return marketType.displayName.replace('{home}', home).replace('{away}', away);
        },
    },
    created() {
        const isEmptyOrIncorrectFilterQuery =
            !this.$route.query.filter || !Object.keys(this.currentCategory).includes(this.$route.query.filter);
        if (isEmptyOrIncorrectFilterQuery) {
            this.setCurrentTab({ key: filterCategory.ALL });
        }
    },
    watch: {
        '$route.query.filter': {
            immediate: true,
            handler(value) {
                if (value) {
                    this.currentFilter = value;
                }
            },
        },
        socketFilter: {
            immediate: true,
            handler(filters) {
                if (!this.$isEmpty(filters) && this.isEventPageSocketsEnabled && !this.isSocketChannelRequested) {
                    this.$rs.rsSocket.then(({ socket }) => {
                        this.$rs
                            .requestChannel(
                                socket,
                                `live_event_${this.event.id}`,
                                ({ data }) => {
                                    const { value } = data ? JSON.parse(data) : {};
                                    if (!value) return;
                                    if (value.scoreboardResponse) this.$store.commit(sport.mutation.UPDATE_SINGLE_EVENT_SCOREBOARD, value);
                                    if (value.odds) this.$store.commit(sport.mutation.UPDATE_SINGLE_EVENT_ODDS, value);
                                },
                                {
                                    data: { filters },
                                    metadata: 'data',
                                }
                            )
                            .then((cancel) => {
                                // call cancel() to unsubscribe the channel
                                this.socketCancel = cancel;
                                this.isSocketChannelRequested = true;
                            });
                    });
                }
            },
        },
    },
    beforeDestroy() {
        if (this.socketCancel) {
            this.socketCancel();
        }
    },
};
</script>

<style scoped lang="scss">
.event {
    position: relative;

    .widget-wrapper {
        border-top: $event-header-border-width solid $event-header-border-color-mobile;

        ::v-deep .disclaimer {
            border-bottom: 1px solid $border-color;
        }
    }

    &-ended {
        padding: 64px 50px 70px;
        text-align: center;

        @include oldschool {
            padding-left: 12px;
            padding-right: 12px;
        }

        h4 {
            font-size: 18px;
            margin-bottom: 8px;
        }

        p {
            font-size: 14px;
            margin-bottom: 16px;
        }

        &-links {
            padding-top: 24px;

            .event-ended-link {
                display: inline-block;
                margin: 0 12px;
            }
        }
    }

    .tabs-container.presto-tabs {
        margin: 8px 8px 0;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
    }

    .event-tabs {
        margin-bottom: 16px;
        background: $light-grey;

        &-event-ended {
            pointer-events: none;
            cursor: default;
            overflow: hidden;
            opacity: 0.5;
        }

        &-with-markets {
            margin-bottom: 0;
        }

        ::v-deep {
            .tabs-menu {
                padding: 0;

                .tabs-selector {
                    padding-top: 9px;
                    padding-bottom: 9px;
                }
            }
        }
    }

    h3 {
        @extend %h4-font-700;
        text-transform: uppercase;
        padding: 8px 12px 0 12px;
        margin: 0;
    }

    .events {
        &-container {
            position: relative;
        }

        &-sub-container {
            padding: 16px 12px 8px 12px;
        }

        &-sub-header {
            display: flex;
            margin-bottom: 8px;
            align-items: center;
            justify-content: space-between;

            h4 {
                @extend %body-normal-font-700;
                color: $grey-text;
                margin-bottom: 0;
            }

            .icons-right {
                display: flex;
                align-items: center;
                margin: 0 2px 0 auto;

                @include no_flex_box_supported {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: right;
                }
            }

            .best-odds-holder,
            .icon-boosted {
                margin-left: 8px;
            }
        }
    }

    &-title {
        display: flex;
        align-items: center;
        flex: 1;
    }

    &-info {
        margin-left: 8px;
        line-height: 0;
        cursor: pointer;

        svg.event-info-icon {
            width: 16px;
            height: 16px;
            fill: $dark-green;
        }
    }

    .error-image {
        @include maxxmain {
            width: 100px;
        }
    }
}
</style>
