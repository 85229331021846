import { render, staticRenderFns } from "./CasinoCollectionTitle.vue?vue&type=template&id=59ce34ff&scoped=true"
import script from "./CasinoCollectionTitle.vue?vue&type=script&lang=js"
export * from "./CasinoCollectionTitle.vue?vue&type=script&lang=js"
import style0 from "./CasinoCollectionTitle.vue?vue&type=style&index=0&id=59ce34ff&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ce34ff",
  null
  
)

export default component.exports