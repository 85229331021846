import store, { action, getter, mutation } from './store';
import endpoints from './endpoints';
import modules from './store/modules';

export { ROUND_STATUS, MARKET_TABS, VIRTUAL_LEAGUE_MAP } from './const/virtual-sports';
export { jackpotType, jackpotStatus, jackpotRequestType } from './const/jackpot-const';
export { widgetType } from './const/widget-const';
export { CATEGORY_MAP } from './const/marketselector';
export { WS_FILTERS_TEMPLATE } from './const/ws-filter-template';
export {
    category as filterCategory,
    sportCategory,
    sportFilters,
    EXCLUDED_MARKET_NAMES,
    NO_SORT_ON_ALL_TAB_SPORTS,
    marketType,
    marketPreferenceType,
    DEFAULT_EVENT_QUERY_AMOUNT,
    LIVE_EVENT_TYPE,
    MAX_LIVE_EVENT_QUERY_AMOUNT,
} from './const/market-const';
export {
    WHT_TYPES,
    GROSS_TAX_TYPES,
    whtTax,
    grossTax,
    betType,
    betStatus,
    bonusType,
    stakeFormat,
    betslipRedirects,
    singleBetslipOddStatus,
    cancellationReasonType,
    cancellationReasonTranslationKey,
    cancellationReasonLinkId,
} from './const/betslip-const';
export { socialShareType, socialShareLink } from './const/social-share-const';
export { EventType, DEFAULT_TAKE } from './const/events-const';

const { betslip } = modules;
const sport = { action, mutation, getter };

export { betslipType } from './store/modules/betslip';

export { myBetsType, sportType, marketTypeCategory } from './store';

export { sport, betslip, endpoints };

export default store;
