<template>
    <div class="menu-item" :class="{ selected }">
        <router-link
            :to="link"
            :class="{ link: true, presto: isPresto }"
            :event="link.path || link.name ? 'click' : ''"
            :tag="link.path || link.name ? 'a' : 'div'"
            @click.native="onItemClick()"
        >
            <div class="table">
                <div class="row-cell align-middle details" @click="onIsLeftSideClick(true)">
                    <SvgIcon v-if="item.icon" :iconId="item.icon" class="icon-status icon-size-medium" />
                    <slot v-else name="icon"></slot>
                    <span class="menu-text name" :class="{ underline }">{{ item.text }}</span>
                    <Badge class="menu-text" v-if="item.badge" :text="item.badge" :type="item.badgeType" :bgColor="item.badgeColor" />
                </div>
                <div
                    class="row-cell align-middle control"
                    @click="onIsLeftSideClick(false)"
                    v-if="item.control && (item.control.text || item.control.icon)"
                >
                    <span class="menu-text" v-if="item.control.text">{{ item.control.text }}</span>
                    <SvgIcon
                        v-if="!item.control.iconDisabled"
                        :iconId="item.control.icon || 'arrow_right'"
                        :class="item.control.iconSize || 'icon-size-very-small'"
                    />
                </div>
            </div>
        </router-link>
        <slot name="content" />
    </div>
</template>

<script>
import Badge from '@/components/Badge.vue';

export default {
    name: 'MenuItem',
    components: { Badge },
    props: {
        link: {
            type: [String, Object],
            default: () => ({}),
        },
        item: {
            type: Object,
            default: () => ({}),
        },
        selected: {
            type: Boolean,
            default: false,
        },
        underline: {
            type: Boolean,
            default: false,
        },
        isPresto: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onIsLeftSideClick(isLeft) {
            this.$emit('onIsLeftSideClick', isLeft);
        },
        onItemClick() {
            this.$emit('onItemClick', this.item);
        },
    },
};
</script>

<style lang="scss" scoped>
.menu-item {
    background: white;
    position: relative;

    > .link {
        @extend %menu_item;
        padding: $left-menu-item-padding;
    }

    & + & > .link,
    &:first-child > .link,
    &:last-child > .link {
        border-top: $left-menu-item-border;
    }

    span:not(.menu-text) {
        display: block;
    }

    ::v-deep .icon-status,
    ::v-deep .menu-text {
        vertical-align: middle;
        margin-right: 5px;

        & + svg {
            margin-left: 12px;
        }
    }

    & > .link:hover,
    & > .link:active {
        color: initial;
    }

    .details {
        @include only_mini {
            @include maxonecolumn {
                width: ($breakpoint-sm-tablet - $left-menu-item-padding-and-control-width);
            }
            @include maxsmtablet {
                width: ($breakpoint-wp - $left-menu-item-padding-and-control-width);
            }
            @include maxwp {
                width: ($breakpoint-x-main - $left-menu-item-padding-and-control-width);
            }
            @include maxxmain {
                width: ($breakpoint-basic - $left-menu-item-padding-and-control-width);
            }
            @include maxbasic {
                width: ($breakpoint-oldschool - $left-menu-item-padding-and-control-width);
            }
            @include oldschool {
                width: ($breakpoint-oldoldschool - $left-menu-item-padding-and-control-width);
            }
            @include oldoldschool {
                width: (200px - $left-menu-item-padding-and-control-width);
            }
        }
    }

    .control {
        text-align: right;
        min-width: $left-menu-item-control-width;
    }

    &.selected > .link {
        @include all_but_mini() {
            border-left: $menu-item-border-width solid $menu-item-border-color;
        }
        color: $menu-item-color-selected;
        background: $sidebar-menu-background-selected;
        padding-left: $left-menu-item-padding - $menu-item-border-width;
        font-weight: bold;

        .name {
            text-decoration-color: $menu-item-color-selected;
        }

        @include oldschool {
            border-left: none;
        }

        svg.svg-icon {
            fill: $menu-item-color-selected;
        }
    }

    &.sub-menu-item {
        > .link {
            text-transform: none;
            padding-left: 31px;

            @include oldschool {
                padding-left: 12px;
            }
        }

        &,
        &.selected {
            text-transform: none;
        }

        &.selected > .link {
            border-left: none;
        }
    }

    .presto {
        cursor: initial;
    }
}
</style>
